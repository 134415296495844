import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable()
export class ComponentSettingService {

  public compSetting: any = {
    linkMenuSection: true,
    languageSection: true,
    currencySection: true,
    loginSection: true,
    footerSection: true,
    isHeaderRequired: true,
    messageSection: '',
  }

  public urlCompSetting: any = {
    uhf: true,
    linkmenu: true,
    language: true,
    currency: true,
    loginsection: true,
    footer: true,
    type: true,
    message: true,
  }

  public urlParamCheck: any = [];
  public urlTooltipCheck: boolean = false;
  public userDefinedMessage: any = null;
  public defaultMessage: any = null;
  public customMessage: any = null;
  public static defaultMessageRecieved: boolean = false;
  public static userMessageRecieved: boolean = false;
  public static userCustomMessageRecieved: boolean = false;
  public static clientLogoutCallback: any = null;

  public waMainContainerId: string = 'wcaMainContent';
  public waFooterContainerId: string = 'wcaFooter';

  private settingChangeSub = new BehaviorSubject<any>(this.compSetting);
  public UHFSettingChange$ = this.settingChangeSub.asObservable();
  public update(newCompSettings: any) {
    this.settingChangeSub.next(newCompSettings);
  }

  private outlineTriggerSub = new Subject<any>();
  public UHFoutlineTrigger$ = this.outlineTriggerSub.asObservable();
  public triggerOutline(element: any = null) {
    this.outlineTriggerSub.next(element);
  }

  private SSOmodalOpenCloseSub = new Subject<any>();
  public SSOmodalOpenClose$ = this.SSOmodalOpenCloseSub.asObservable();
  public SSOmodalOpenClose(openModal: boolean) {
    this.SSOmodalOpenCloseSub.next(openModal);
  }

  constructor(private activatedRoute: ActivatedRoute, private router: Router) {

    // URL route component setting
    this.activatedRoute.queryParams.subscribe(queryParams => {
      Object.keys(queryParams).forEach(param => {
        if (param in this.urlCompSetting) {
          // if(param === 'type'){
          //   this.urlTooltipCheck = true;
          // }

          this.urlCompSetting[param] = queryParams[param];

          this.reviseCompSetting(param, queryParams[param]);
        }
      })
    });
  }

  // Overriding the settings wrt URL params
  reviseCompSetting(setting, value) {
    switch (setting) {
      case 'uhf':
        value == 'true' ? this.enableUniversalHeader() : this.disableUniversalHeader()
        break;

      case 'linkmenu':
        value == 'true' ? this.setLinkMenuSection(true) : this.setLinkMenuSection(false)
        break;

      case 'language':
        value == 'true' ? this.setLanguageSection(true) : this.setLanguageSection(false)
        break;

      case 'currency':
        value == 'true' ? this.setCurrencySection(true) : this.setCurrencySection(false)
        break;

      case 'loginsection':
        value == 'true' ? this.setLoginSection(true) : this.setLoginSection(false)
        break;

      case 'footer':
        value == 'true' ? this.setfooterSection(true) : this.setfooterSection(false)
        break;

      // case 'type':
      //   this.setmessageSection(value)
      //   break;

      default:
        break;
    }
  }

  getComponenetSettings() {
    return this.compSetting;
  }

  gettypeSetting() {
    return this.compSetting['messageSection']
  }

  /** Entire UHF Kill switch */
  enableUniversalHeader() {
    this.compSetting.isHeaderRequired = true;
    this.update(this.compSetting);
  }

  disableUniversalHeader() {
    this.compSetting.isHeaderRequired = false;
    this.update(this.compSetting);
  }

  /** Component Level Setting Setters */
  setComponentSettings(newSettings) {
    Object.keys(newSettings).forEach(s => {
      if (s in this.compSetting) {
        this.compSetting[s] = newSettings[s];
      }
    });
  }

  // Refactor: Also allow individual component setting later
  setLinkMenuSection(setting: boolean | Array<number>) {
    this.compSetting.linkMenuSection = setting;
    this.update(this.compSetting);
  }

  setLanguageSection(setting: boolean) {
    this.compSetting.languageSection = setting;
    this.update(this.compSetting);
  }

  setCurrencySection(setting: boolean) {
    this.compSetting.currencySection = setting;
    this.update(this.compSetting);
  }

  setLoginSection(setting: boolean) {
    this.compSetting.loginSection = setting;
    this.update(this.compSetting);
  }

  setfooterSection(setting: boolean) {
    this.compSetting.footerSection = setting;
    this.update(this.compSetting);
  }

  setmessageSection(value: string) {
    this.compSetting.messageSection = value;
    this.update(this.compSetting);
  }

  /** Custom logout callback */
  registerLogoutCallback(fn) {
    if (fn && {}.toString.call(fn) === '[object Function]') {
      ComponentSettingService.clientLogoutCallback = fn;
    }
  }

  /** Custom message Via URL Param **/
  registerTooltipMessage(msg) {
    if (msg && Object.keys(msg).length) {
      ComponentSettingService.userMessageRecieved = true;
      this.userDefinedMessage = msg;
    }
    else {
      ComponentSettingService.userMessageRecieved = false;
    }
  }

  /** Default Message in case no URL Param **/
  // registerDefaultTooltip(msg){
  //   if (msg && Object.keys(msg).length) {
  //     ComponentSettingService.defaultMessageRecieved = true;
  //     this.defaultMessage = msg;
  //   }
  // }

    // Custom Message load observable
  // private customTooltipMessageData = new BehaviorSubject<any>(null);
  // public customMessageData$ = this.customTooltipMessageData.asObservable();
  // public updateTooltipMessageSubject(data) {
  //   ComponentSettingService.userCustomMessageRecieved = true;
  //   this.customTooltipMessageData.next(data);
  // }

  /** Getter/setter for main container ID for web accessibility */
  getWaMainContainerId() {
    return this.waMainContainerId;
  }
  setWaMainContainerId(id: string) {
    this.waMainContainerId = id;
  }

  /** Getter/setter for footer container ID for web accessibility */
  getWaFooterContainerId() {
    return this.waFooterContainerId;
  }
  setWaFooterContainerId(id: string) {
    this.waFooterContainerId = id;
  }
}
