import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AaFocusOutlineComponent } from '../../_components/aa-focus-outline/aa-focus-outline.component'

@NgModule({
  imports: [
    CommonModule
  ],
  exports: [
    AaFocusOutlineComponent
  ],
  declarations: [
    AaFocusOutlineComponent
  ]
})
export class FocusOutlineModule { }
