import { Injectable } from '@angular/core';
import { Endpoints } from '../_config/endpoints';
import { BaseHttpService } from './base-http-calls.service';
import { Observable } from 'rxjs';
import { UserdataService } from './userdata.service';

@Injectable()
export class SsoApiCalls {


    constructor(
        private baseHttp: BaseHttpService,
        private userDataService: UserdataService
    ) { }



    urls: any;






    whichSsoToCall = (whichSsoCall: string, body: any = {}, headers: any = {}, parameters: string = "", socialProviderOrUserId?: string) => {


        switch (whichSsoCall) {
            case 'logIn':
                return this.baseHttp.post(this.urls["logIn"] + parameters, body, headers);
            case 'signUp':
                return this.baseHttp.post(this.urls["signUp"] + parameters, body, headers);
            case 'logOut':
                return this.baseHttp.post(this.urls["logOut"] + parameters, body, headers);
            case 'forgotPassword':
                return this.baseHttp.post(this.urls["forgotPassword"] + parameters, body, headers);
            case 'byOrigin':
                return this.baseHttp.get(this.urls["byOrigin"] + parameters, headers);
            case 'refresh':
                return this.baseHttp.post(this.urls["refresh"] + parameters, body, headers);
            case 'userDetails':
                return this.baseHttp.get(this.urls["userDetails"].replace("{userId}", socialProviderOrUserId) + parameters, headers);
            case 'updateUserDetails':
                return this.baseHttp.patch(this.urls["userDetails"].replace("{userId}", socialProviderOrUserId) + parameters, body, headers);
            case 'activateKeys':
                return this.baseHttp.post(this.urls["activateKeys"], body, headers);
            case 'weChatLogin':
                return this.baseHttp.get(this.urls["weChatLogin"] + parameters, headers);
            case 'weChatSignupVerify':
                return this.baseHttp.get1(this.urls["weChatSignupVerify"] + parameters, headers);
            case 'backwardLogin':
                return this.baseHttp.post1(this.urls["backwardLogin"] + parameters, body, headers);
            case 'backwardRefresh':
                return this.baseHttp.get1(this.urls["backwardRefresh"] + parameters, headers);
            case 'backwardLogout':
                return this.baseHttp.post1(this.urls["backwardLogout"] + parameters, body, headers);


        }

    }



}
