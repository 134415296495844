export const KEYS = {
	backspace: 8,
	upArrow: 38,
	downArrow: 40,
	leftArrow: 37,
	rightArrow: 39,
	enter: 13,
	esc: 27,
	space: 32,
	home: 36,
	end: 35,
	tab: 9
};


export interface TooltipDefaultMessage{
	"login"?:"message1"|"message2"|"message3",
	"nonLogin"?:"message1"|"message2"|"message3"
}

export interface ToolTipModel{
	"mobileDisplay": string,
	"topLiner": string,
	"topCta": string,
	"topCtaLink": string,
	"topCtaNewTab": string,
	"bottomLiner": string,
	"bottomCta": string,
	"bottomCtaLink": string
};


export interface TooltipDataFromBucketModel{
	"nonLogin": [
	{
	"message1": [ToolTipModel]
	},
	{
	"message2": [ToolTipModel]
	}
	],
	"login": [
	{
	"message1": [ToolTipModel]
	},
	{
	"message2": [ToolTipModel]
	}
	]
	}
