import { Component, OnInit, Input } from '@angular/core';
import { UhfSsoService } from '../../../_services/uhfssoservice.service';

@Component({
  selector: 'sso-activation',
  template: `<div class='sso-activation'>
   <h2>{{localeTexts?.signupTab?.activateYourAccount}}</h2>
  <div class='sso-activation-info sso-row'>
    <div class='sso-activation-icon' aria-hidden='true' [style.backgroundImage]="'url('+ localeTexts?.signupTab?.activationIcon +')'"></div>

    <div class='sso-activation-details sso-column'>
      <p>{{localeTexts?.signupTab?.emailWithActivationLink}}</p>
      <div *ngIf='email' class='sso-activation-email'>
        <mat-icon>check</mat-icon> 
        <div>{{email}}</div>
      </div>
    </div>
  </div>

  <p class='sso-activation-resend'>
    {{localeTexts?.signupTab?.noEmail}} 
    <a href='#' (click)='clickResendEmail($event)' [ngClass]='{"sso-activation-disabled":resendActivationLink}'>
      {{localeTexts?.signupTab?.resendLink}}
    </a>
  </p>
</div>
`,
  styles: [`button{border:none;background:0 0;cursor:pointer}.sso-social-items{display:flex;flex-direction:row;justify-content:space-around}.sso-social-items .sso-social-item{display:flex;flex-direction:column;align-items:center;cursor:pointer}.sso-social-items .sso-social-item .sso-social-icon{width:50px;height:50px;border-radius:50%}.sso-row{display:flex;flex-direction:row}.sso-column{display:flex;flex:1;flex-direction:column}.sso-button{width:100%;font-size:1.18rem;background:#e10000;border-radius:4px;border:none;padding:15px;color:#fff;cursor:pointer;display:flex;justify-content:center}.sso-button:disabled{cursor:progress}ul{list-style-type:none;padding:0}.sso-error-message{box-sizing:border-box;background-color:#ffebee;padding:12px;margin:15px 0;border-radius:5px;color:#e10000;width:100%;display:flex;font-size:.9rem;align-items:flex-start}.sso-error-message div{margin-left:10px;display:block;align-self:center}.sso-error-message a{text-decoration:underline}.sso-activation{padding:15px 25px}.sso-activation-icon{background-size:60px 60px;background-repeat:no-repeat;width:60px;height:60px}.sso-activation-details{justify-content:center;margin-left:20px}h2{font-size:1.25rem;font-weight:500;margin:0;padding-bottom:20px}.sso-activation-info{display:flex;flex-direction:row;padding-bottom:25px;border-bottom:1px solid #bfc5cf}.sso-activation-resend{margin:20px 0}p{font-size:.95rem}.sso-activation-email{display:flex;align-items:center;color:#067e41;word-break:break-word;font-weight:700;align-items:center}.sso-activation-disabled{cursor:not-allowed;opacity:.5;display:inline-block;pointer-events:none;text-decoration:none}`]
})
export class SsoActivationComponent implements OnInit {

  @Input() localeTexts: any;
  public email: string;

  public resendActivationLink = false;

  constructor(private uhfSsoService: UhfSsoService) { }

  ngOnInit() {
    this.email = this.uhfSsoService.getTempEmail();
  }

  clickResendEmail(e: Event) {
    if (this.resendActivationLink) return;
    e.preventDefault();
    this.resendActivationLink = true; 
    this.uhfSsoService.userActivateKeys(this.email);
  }

}
