import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { LocaleService } from '../../_services/locale.service';
import { UserdataService } from '../../_services/userdata.service';
import { of, Subscription, } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { VIEW_COMPONENTS, ERRORS, ACCEPTED_PARAMS } from './sso-helper';
import { FormGroup } from '@angular/forms';
import { UhfSsoService } from '../../_services/uhfssoservice.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ComponentSettingService } from '../../_services/component-setting.service';

@Component({
  selector: 'aa-sso-widget',
  template: `<div role='dialog' aria-modal="true" id='sso-login-signup-widget' tabindex="0" [attr.aria-label]="localeTexts?.main?.widgetTitle">
  <button *ngIf='!isMobileView' (click)='closeModal()' class='sso-close-btn'>&times;</button>
  
  <div class='sso-signup-login'>
    <div class='sso-row'>
      <div class='sso-column sso-left-panel'>
        <section class='sso-login-signup-section'>

          <sso-activation
            *ngIf='selectedViewComponent === getViewComponents().ACTIVATION'
            [localeTexts]="localeTexts"
          >
          </sso-activation>
        
          
          <sso-user-details 
            *ngIf='selectedViewComponent === getViewComponents().USER_DETAILS || selectedViewComponent === getViewComponents().WECHAT_USER_DETAILS'
            [isFromWeChat]='selectedViewComponent === getViewComponents().WECHAT_USER_DETAILS'
            (changeViewComponent)="changeViewComponent($event)"
            [localeTexts]="localeTexts"
            [form]='signUpForm'
            (onSignUpError)='onSignUpError($event)'
          >
          </sso-user-details>
          
          
          <forgot-password 
            *ngIf='selectedViewComponent === getViewComponents().FORGOT_PASSWORD'
            (changeViewComponent)="changeViewComponent($event)"
            [localeTexts]="localeTexts"
          >
          </forgot-password>

          <aa-tabs  
            *ngIf='selectedViewComponent === getViewComponents().LOGIN || selectedViewComponent === getViewComponents().SIGNUP' 
            class='sso-tab-nav'
          >
            <aa-tab 
              [title]='localeTexts?.tabs?.login' 
              role="tabpanel" 
              [active]='selectedViewComponent === getViewComponents().LOGIN'
            >
              <sso-login 
                (changeViewComponent)="changeViewComponent($event)"
                [localeTexts]="localeTexts"
              ></sso-login>
            </aa-tab>
            <aa-tab 
              [title]='localeTexts?.tabs?.signup' 
              role="tabpanel" 
              [active]='selectedViewComponent === getViewComponents().SIGNUP'
            >
              <sso-signup
                (changeViewComponent)="changeViewComponent($event)"
                (onSignUpSubmit)="onSignUpSubmit($event)"
                [localeTexts]="localeTexts"
                [errorType]="signUpErrorType"
              ></sso-signup>
            </aa-tab>
          </aa-tabs>
        </section>
        <sso-footer></sso-footer>
      </div>

      <div *ngIf='!isMobileView' class='sso-column sso-right-panel'>
        <sso-sidebar
          [header]='localeTexts?.rightPanel?.headline'
          [body]='localeTexts?.rightPanel?.body'
        >
        </sso-sidebar>
      </div>
    </div>
  </div>

</div>
`,
  styles: [`button{border:none;background:0 0;cursor:pointer}.sso-social-items{display:flex;flex-direction:row;justify-content:space-around}.sso-social-items .sso-social-item{display:flex;flex-direction:column;align-items:center;cursor:pointer}.sso-social-items .sso-social-item .sso-social-icon{width:50px;height:50px;border-radius:50%}.sso-row{display:flex;flex-direction:row}.sso-column{display:flex;flex:1;flex-direction:column}.sso-button{width:100%;font-size:1.18rem;background:#e10000;border-radius:4px;border:none;padding:15px;color:#fff;cursor:pointer;display:flex;justify-content:center}.sso-button:disabled{cursor:progress}ul{list-style-type:none;padding:0}.sso-error-message{box-sizing:border-box;background-color:#ffebee;padding:12px;margin:15px 0;border-radius:5px;color:#e10000;width:100%;display:flex;font-size:.9rem;align-items:flex-start}.sso-error-message div{margin-left:10px;display:block;align-self:center}.sso-error-message a{text-decoration:underline}.sso-column.sso-left-panel{flex:60%;justify-content:space-between}.sso-column.sso-right-panel{flex:40%;background:#eceff0;border-left:1px solid #bfc5cf}.sso-close-btn{padding:10px;position:absolute;font-size:2.2rem;font-weight:300;cursor:pointer;right:0;line-height:.7}.sso-features{padding:25px;margin-top:20px}.sso-login-signup-section{min-height:440px}`],
  host: {
    '(document:keydown)': 'focusTrap($event)',
    '(document:keydown.escape)': 'closeModal()'
  }
})
export class AaSsoComponent implements OnInit, OnDestroy {

  public allSub: Subscription[] = [];
  public currentLocale: string;
  public localeTexts: any;
  public selectedViewComponent: string;
  public signUpForm: FormGroup;
  public signUpErrorType: string;

  @Input() isMobileView: boolean = false;
  @Output() openSsoModal: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() closeSsoModal: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private localeService: LocaleService,
    private userDataService: UserdataService,
    private uhfSsoService: UhfSsoService,
    private activatedRoute: ActivatedRoute,
    private css: ComponentSettingService,
    private router: Router
  ) { }

  ngOnInit() {
    this.selectedViewComponent = VIEW_COMPONENTS.LOGIN;
    let ssoLSub = this.userDataService.ssoLoginChange$.subscribe((userData, ) => {
      if (!userData) return;
      if (userData.ssoError === ERRORS.USER_INCOMPLETE_DETAILS) {
        this.selectedViewComponent = VIEW_COMPONENTS.USER_DETAILS;
      } else if (userData.ssoError === ERRORS.USER_ALREADY_EXISTS) {
        this.onSignUpError(ERRORS.USER_ALREADY_EXISTS);
      } else if (userData.status === true && !userData.ssoError && (this.selectedViewComponent === VIEW_COMPONENTS.LOGIN || this.selectedViewComponent === VIEW_COMPONENTS.SIGNUP)) {
        this.closeModal();
      }
    })
    this.allSub.push(ssoLSub);

    this.setDefaultView();
    let locSub = this.userDataService.$localeChange
      .pipe(
        mergeMap(locale => {
          if (this.currentLocale != locale) {
            this.currentLocale = locale;
            return this.localeService.loadRemoteLocale(['sso'], this.userDataService.getEnvironment())
          } else {
            return of([])
          }
        }),
        map(resp => {
          this.onLocaleLoad(resp);
        }),
        catchError(err => {
          let resp = this.localeService.getFbLocaleSections(['sso']);
          this.onLocaleLoad(resp);
          return of([])
        })
      )
      .subscribe()
    this.allSub.push(locSub);

    // Onload attempt to login, if previouly logged-in
    this.uhfSsoService.setSSOEnvironment(this.userDataService.getEnvironment());

    // HACK: Quick Fix for PWA Select Price Mismatch
    if (this.userDataService.getUhfClientId() === 'PWA') {

      // Wait for PWA trigger, no action if PWA Wrap
      this.userDataService.pwaLoaded$.subscribe(isPwaWrap => {
        if (!isPwaWrap) {

          // If not PWA wrap, perform normal flow else PWA Wrap will call the below function
          this.uhfSsoService.refreshUserSession();
        }
      })
    } else {

      // For other Apps
      if(this.userDataService.getUhfClientId()!='PAYMENTS_PWA_WRAP')
      this.uhfSsoService.refreshUserSession();
    }

    let subChangeView = this.uhfSsoService.ssoTriggerView.subscribe((viewComponent: string) => {
      if (viewComponent === VIEW_COMPONENTS.CLOSE_MODAL) {
        // this.closeModal(); 
        this.css.SSOmodalOpenClose(false);
      } else {
        this.selectedViewComponent = viewComponent;
        // this.openModal();
        this.css.SSOmodalOpenClose(true);
      }
    });
    // this.allSub.push(subChangeView);
  }

  onSignUpSubmit(signUpForm: FormGroup) {
    this.signUpForm = signUpForm;
    this.selectedViewComponent = VIEW_COMPONENTS.USER_DETAILS;
  }

  onSignUpError(errorType: string) {
    setTimeout(() => {
      this.signUpErrorType = errorType;
      this.selectedViewComponent = VIEW_COMPONENTS.SIGNUP
    }, 1);
  }

  onLocaleLoad(resp: any) {
    if (resp.length) {
      this.localeTexts = resp[0];
    }
  }

  closeModal() {
    this.closeSsoModal.emit();
  }

  openModal() {
    this.openSsoModal.emit(false);
  }

  getViewComponents() {
    return VIEW_COMPONENTS;
  }

  changeViewComponent(view: string) {
    if (VIEW_COMPONENTS.CLOSE_MODAL === view) {
      this.closeModal();
      return;
    }
    // console.log("changing", view);
    this.selectedViewComponent = view;
  }

  focusTrap(e: KeyboardEvent) {
    let widget = document.getElementById('login-signup');
    if (widget && e.code === 'Tab') {
      let focusableElements = widget.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
      let focusIndex = Array.prototype.indexOf.call(focusableElements, document.activeElement);
      if (e.shiftKey) {
        if (focusIndex - 1 === 0) {
          let lastElement = focusableElements[focusableElements.length - 1] as HTMLElement;
          lastElement.focus();
        }
      } else if (focusIndex + 1 === focusableElements.length) {
        let firstElement = focusableElements[0] as HTMLElement;
        firstElement.focus();
      }
    }
  }

  ngOnDestroy() {
    for (let sub of this.allSub) {
      sub.unsubscribe();
    }
  }

  setDefaultView() {
    let sub = this.activatedRoute.queryParams.subscribe(params => {
      let copyParams = JSON.parse(JSON.stringify(params));
      if (copyParams === []) return;
      if (copyParams[ACCEPTED_PARAMS.LOGIN] === 'true') {
        this.selectedViewComponent = VIEW_COMPONENTS.LOGIN;
        this.openModal();
        delete copyParams[ACCEPTED_PARAMS.LOGIN];
        this.updateRoute(copyParams);
      } else if (copyParams[ACCEPTED_PARAMS.SIGNUP] === 'true') {
        this.selectedViewComponent = VIEW_COMPONENTS.SIGNUP;
        this.openModal();
        delete copyParams[ACCEPTED_PARAMS.SIGNUP];
        this.updateRoute(copyParams);
      } else if (copyParams[ACCEPTED_PARAMS.FORGOT_PASSWORD] === 'true') {
        this.selectedViewComponent = VIEW_COMPONENTS.FORGOT_PASSWORD;
        delete copyParams[ACCEPTED_PARAMS.FORGOT_PASSWORD];
        this.updateRoute(copyParams);
        this.openModal();
      }
    });
    this.allSub.push(sub);
  }

  updateRoute(params: Params) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: params,
      replaceUrl: true
    });
  }
}
