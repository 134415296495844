export interface RadioButton {
    value: string;
    text: string;
}

export class RadioButtonItem implements RadioButton {
    value: string;
    text: string;

    constructor(value = '', text = '') {
        this.value = value;
        this.text = text;
    }
}