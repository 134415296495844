import {
    Injectable,
    ComponentFactoryResolver,
    ApplicationRef,
    Injector,
    EmbeddedViewRef,
    ComponentRef
} from '@angular/core';

@Injectable()
export class DOMService {

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private applicationRef: ApplicationRef,
        private injector: Injector,
    ) { }

    appendComponentToBody(component: any) {
        //create a component reference
        const componentRef = this.componentFactoryResolver.resolveComponentFactory(component)
            .create(this.injector);

        // attach component to the appRef so that so that it will be dirty checked.
        this.applicationRef.attachView(componentRef.hostView);

        // get DOM element from component
        const domElem = (componentRef.hostView as EmbeddedViewRef<any>)
            .rootNodes[0] as HTMLElement;

        document.body.appendChild(domElem);

        return componentRef;
    }

    appendComponentToIDContainer(component: any, id: any) {
        //create a component reference
        const componentRef = this.componentFactoryResolver.resolveComponentFactory(component)
            .create(this.injector);
            componentRef.instance['ElementRoot'] = 'airasia_widget_3';

        // attach component to the appRef so that so that it will be dirty checked.
        this.applicationRef.attachView(componentRef.hostView);

        // get DOM element from component
        const domElem = (componentRef.hostView as EmbeddedViewRef<any>)
            .rootNodes[0] as HTMLElement;

        let $id = document.createElement("DIV");
        $id.setAttribute('id', id);
        let $innerDiv = document.createElement("DIV");
        $innerDiv.setAttribute('class', 'main');
        $id.appendChild($innerDiv);
        document.body.appendChild($id);
        $innerDiv.appendChild(domElem);

        return componentRef;
    }

    removeComponentFromBody(componentRef: ComponentRef<any>) {
        this.applicationRef.detachView(componentRef.hostView);
        componentRef.destroy();
    }
}




