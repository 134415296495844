import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { VIEW_COMPONENTS, ERRORS, isEmail } from '../sso-helper';
import { UhfSsoService } from '../../../_services/uhfssoservice.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'forgot-password',  
  template: `<div class='sso-forgot-password'>
  <ng-container *ngIf='!passwordSent'>
    <h2>{{localeTexts?.forgotPassword?.forgotPassword}}</h2>
    <p>{{localeTexts?.forgotPassword?.enterEmail}}</p>
    <form 
      [formGroup]="form" 
      (ngSubmit)='onSubmit()'
      class='sso-forgot-password-form'
    >

      <div *ngIf='errorMessage' class='sso-error-message' role="alert">
        <mat-icon aria-hidden='true'>error</mat-icon>
        <div>{{errorMessage}}
          <ng-container *ngIf='errorCustomerSupportInactive'>
            <a [href]='localeTexts?.errors?.customerSupportLinkUrl' target='_blank'>
              {{localeTexts?.errors?.customerSupportLink}}
            </a>
            {{localeTexts?.errors?.userInactiveCombinedEnd}}
          </ng-container>
          <ng-container *ngIf='errorCustomerSupportLocked'>
            <a [href]='localeTexts?.errors?.customerSupportLinkUrl' target='_blank'>
                {{localeTexts?.errors?.customerSupportLink}}
            </a>
            {{localeTexts?.errors?.customerSupportLinkEndLocked}}
          </ng-container>
          <ng-container *ngIf='errorShowActivatedLink'>
            <a *ngIf='errorShowActivatedLink' (click)='sendActivationLink($event)' href=''>
              {{localeTexts?.errors?.userNotActivatedLink}}
            </a>
            {{localeTexts?.errors?.userNotActivatedEnd}}
          </ng-container>
        </div>
      </div>
      
      <div class='sso-row'>
        <aa-input
          [id]="'sso-forgot-password-email-input'"
          [labelText]="localeTexts?.main?.email"
          formControlName="email"
          [type]="'email'"
          [form]="form"
          [maxLength]='64'
          [autocomplete]="'email'"
          [focusOnInit]='true'
          [error]='errorMessage ? true : false'
        >
        </aa-input>
      </div>
      <div class='sso-row'>
        <button type='submit' #submitButton class='sso-button' [disabled]='isLoading'>
          <mat-spinner *ngIf='isLoading' class='white-spinner' [diameter]="22"></mat-spinner>
          <ng-container *ngIf='!isLoading'>
            {{localeTexts?.forgotPassword?.sendResetLink}}
          </ng-container>
        </button>
      </div>
    </form>
  </ng-container>


  <ng-container *ngIf='passwordSent'>
    <h2>{{localeTexts?.forgotPassword?.checkYourEmail}}</h2>
    <p>{{(localeTexts?.forgotPassword?.linkToResetYourPassword).replace("{email}", ""). replace(" {}", ":")}}</p>
  
    <div class='sso-forgot-password-check'>
      <mat-icon>check</mat-icon> 
      <div>{{form.value.email}}</div>
    </div>

    <p>{{localeTexts?.forgotPassword?.kindlyClickLink}}</p>
  </ng-container>



  <div>
    <a href='' (click)='clickBackToLogin($event)'>
      <span aria-hidden='true'>â¹ </span>{{localeTexts?.forgotPassword?.backToLogin}}
    </a>
  </div>
</div>`,
  styles: [`button{border:none;background:0 0;cursor:pointer}.sso-social-items{display:flex;flex-direction:row;justify-content:space-around}.sso-social-items .sso-social-item{display:flex;flex-direction:column;align-items:center;cursor:pointer}.sso-social-items .sso-social-item .sso-social-icon{width:50px;height:50px;border-radius:50%}.sso-row{display:flex;flex-direction:row}.sso-column{display:flex;flex:1;flex-direction:column}.sso-button{width:100%;font-size:1.18rem;background:#e10000;border-radius:4px;border:none;padding:15px;color:#fff;cursor:pointer;display:flex;justify-content:center}.sso-button:disabled{cursor:progress}ul{list-style-type:none;padding:0}.sso-error-message{box-sizing:border-box;background-color:#ffebee;padding:12px;margin:15px 0;border-radius:5px;color:#e10000;width:100%;display:flex;font-size:.9rem;align-items:flex-start}.sso-error-message div{margin-left:10px;display:block;align-self:center}.sso-error-message a{text-decoration:underline}.sso-forgot-password{padding:15px 25px}.sso-forgot-password-form{margin-bottom:25px}h2{font-size:1.25rem;font-weight:500;margin:0}p{margin:20px 0;font-size:.95rem}a{font-size:.925}.sso-row,aa-input{width:100%}.sso-button{margin-top:10px}.sso-forgot-password-check{display:flex;align-items:center;color:#067e41;word-break:break-word;font-weight:700;align-items:center}`]
})



export class ForgotPasswordComponent implements OnInit, OnDestroy {

  form: FormGroup;
  @Input() localeTexts: any;
  @Output() changeViewComponent: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('submitButton') submitButton: ElementRef;

  errorMessage: string = '';
  errorCustomerSupportInactive: boolean = false;
  errorCustomerSupportLocked: boolean = false;
  errorShowActivatedLink: boolean = false;

  public allSub: Subscription[] = [];

  public passwordSent: boolean = false;
  public isLoading: boolean = false;
  
  constructor(private uhfSsoService: UhfSsoService) { }

  ngOnInit() {
    this.form = new FormGroup({
      'email': new FormControl(null, [Validators.required, Validators.email, Validators.maxLength(64)])
    });
  }

  onSubmit() {
    this.submitButton.nativeElement.focus();
    this.resetErrors();
    let emailValue = this.form.value.email;
    if (!emailValue) {
      this.setErrorMessage(ERRORS.EMPTY_EMAIL);
      return;
    }
    if (!isEmail(emailValue)) {
      this.setErrorMessage(ERRORS.INVALID_EMAIL);
      return;
    }
    this.isLoading = true;
    let sub = this.uhfSsoService.recoverPassword(emailValue).subscribe(
      recoverPasswordResponse => {
        this.passwordSent = true;
      },
      error => {
        if ((error.error.code === ERRORS.USER_TERMINATED && error.error.failedLoginAttempt < 10)) {
          this.setErrorMessage(ERRORS.USER_DEACTIVATED)
        } else if (error.error.code === ERRORS.NEW_USER) {
          this.setErrorMessage(ERRORS.USER_NOT_ACTIVATED)
        } else {
          this.setErrorMessage(error.error.code);
        }
      }
    )
    sub.add(() => this.isLoading = false);
    this.allSub.push(sub);
  }

  sendActivationLink(e:Event) {
    e.preventDefault();
    let email = this.form.value.email;
    this.uhfSsoService.userActivateKeys(email);
    this.uhfSsoService.setTempEmail(email)
    this.changeViewComponent.emit(VIEW_COMPONENTS.ACTIVATION)
  }

  clickBackToLogin(e:Event) {
    e.preventDefault();
    this.changeViewComponent.emit(VIEW_COMPONENTS.LOGIN);
  }

  setErrorMessage(type:string) {
    var errors = {
      [ERRORS.EMPTY_EMAIL]: () => {
        return this.localeTexts['errors']['emptyEmailAddress'];
      },
      [ERRORS.INVALID_EMAIL]: () => {
        return this.localeTexts['errors']['invalidEmail'];
      },
      [ERRORS.INVALID_USERNAME]: () => {
        return this.localeTexts['errors']['emailNotFound'];
      },
      [ERRORS.USER_TERMINATED]: () => {
        return this.localeTexts['errors']['userTerminated'];
      },
      [ERRORS.USER_LOCKED]: () => {
        this.errorCustomerSupportLocked = true;
        return this.localeTexts['errors']['userLocked'];
      },
      [ERRORS.USER_NOT_ACTIVATED]: () => {
        this.errorShowActivatedLink = true;
        return this.localeTexts['errors']['userNotActivated'];
      },
      [ERRORS.USER_DEACTIVATED]: () => {
        this.errorShowActivatedLink = true;
        return this.localeTexts['errors']['userForgotten'];
      },
      [ERRORS.USER_INACTIVE]: () => {
        this.errorCustomerSupportInactive = true;
        return this.localeTexts['errors']['userInactiveCombined'];
      },
      [ERRORS.SERVER_ERROR]: () => {
        return this.localeTexts['errors']['serverError'];
      }
    }
    this.errorMessage = (errors[type] || errors[ERRORS.SERVER_ERROR])();
  }

  resetErrors() {
    this.errorCustomerSupportInactive = false;
    this.errorCustomerSupportLocked = false;
    this.errorShowActivatedLink = false;
    this.errorMessage = '';
  }

  ngOnDestroy() {
    for (let sub of this.allSub) {
      sub.unsubscribe();
    }
  }
 }
