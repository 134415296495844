import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, forkJoin } from 'rxjs';
import { Endpoints } from '../_config/endpoints';

declare function require(url: string);

@Injectable()
export class LocaleService {

  locales: Object = {
    navMenu: {},
    main: {},
    loginPanel: {},
    footer: {}
  };
  thisRoute: string;
  routeLangs: any;
  userCountryDetails: any;
  fbLocales: any = {};
  currEnv: string;

  constructor(private http: HttpClient) {

    // First time language | currency set
    // if (!this.findCurrentCurrency()) {
    //   // console.log('Default requested for Locale.')
    //   this.setLanguageDefault();
    // }
    if (!this.findCurrentCurrency()) {
      this.setCurrencyDefault();
    }

    // Loading fallback en-gb locales
    this.loadFallbackLocales();
  }


  fetchLocale(localeName: string) {
    return this.locales[localeName];
  }

  fetchLocaleValues(section: any, locale: string) {
    return this.locales[section][locale];
  }

  findCurrentLocale() {
    return this.getCookie('uhfLocale');
  }

  setCurrentLocale(locale: string) {
    this.setCookie('uhfLocale', locale.toLowerCase(), 30)
    return true;
  }

  findCurrentCurrency() {
    return this.getCookie('uhfCurrency');
  }

  setCurrentCurrency(curr: string) {
    this.setCookie('uhfCurrency', curr.toUpperCase(), 30)
    return true;
  }

  loadRemoteLocale(sections: Array<string>, env?: string) {

    this.currEnv = env;
    let currLocale = this.findCurrentLocale() ? this.findCurrentLocale() : 'en-gb';
    let httpReqs = [];
    sections.forEach(s => {
      httpReqs.push(this.http.get(Endpoints.getLocaleEndpoint(s, currLocale, this.currEnv)))
    });

    return forkJoin(httpReqs);
  }

  // Refactor
  setLanguageDefault() {

    let urls = [
      Endpoints.getUserCountry(),
      Endpoints.getLocaleEndpoint('language', 'en-gb', this.currEnv)
    ];
    let httpReqs = [];

    urls.forEach(u => {
      httpReqs.push(this.http.get(u))
    });

    forkJoin(httpReqs).subscribe(resp => {

      // Set default locale
      let defaultCode = resp[1]['default'][resp[0]['country'].toLowerCase()];
      let countryCode = "";
      if (resp[0] && resp[1] && defaultCode) {
        countryCode = defaultCode
      } else {
        countryCode = resp[1]['default']['other'];
      }

      let newLocale = countryCode ? countryCode : (this.findCurrentLocale() ? this.findCurrentLocale() : 'en-gb');
      if (newLocale != this.findCurrentLocale()) {
        this.setCurrentLocale(newLocale);
      }
      this.updateUserSetting({ 'locale': newLocale });

    });
  }

  // Refactor
  setCurrencyDefault() {
    let urls = [
      Endpoints.getUserCountry(),
      Endpoints.getLocaleEndpoint('currency', 'en-gb', this.currEnv)
    ];
    let httpReqs = [];

    urls.forEach(u => {
      httpReqs.push(this.http.get(u))
    });

    forkJoin(httpReqs).subscribe(resp => {

      // Set default locale
      let defaultCode = resp[1]['default'][resp[0]['country'].toLowerCase()];
      let countryCode = "";
      if (resp[0] && resp[1] && defaultCode) {
        countryCode = defaultCode
      } else {
        countryCode = resp[1]['default']['other'];
      }

      this.setCurrentCurrency(countryCode ? countryCode : 'USD');
      this.updateUserSetting({ 'currency': this.findCurrentCurrency() });
    });
  }

  private noLangCurrSub = new BehaviorSubject<any>(null);
  public userSetting$ = this.noLangCurrSub.asObservable();
  public updateUserSetting(data) {
    this.noLangCurrSub.next(data);
  }

  /* -- Utils -- */
  setCookie(name, value, days?: any) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }

  getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return;
  }

  // Fallback Locale stategies
  loadFallbackLocales() {
    this.fbLocales['currency'] = require('../_locale/currency.json');
    this.fbLocales['footer'] = require('../_locale/footer.json');
    this.fbLocales['language'] = require('../_locale/language.json');
    this.fbLocales['loginmenu'] = require('../_locale/loginmenu.json');
    this.fbLocales['loginpanel'] = require('../_locale/loginpanel.json');
    this.fbLocales['main'] = require('../_locale/main.json');
    this.fbLocales['managebookingpanel'] = require('../_locale/managebookingpanel.json');
    this.fbLocales['navmenu'] = require('../_locale/navmenu.json');
  }

  getFbLocaleSections(section) {
    let localeValues = [];
    section.forEach(sec => {
      localeValues.push(this.fbLocales[sec] ? this.fbLocales[sec] : {})
    })
    return localeValues;
  }
}
