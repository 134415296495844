import { Component, OnInit, Input, Output, EventEmitter, OnChanges, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { VIEW_COMPONENTS, ERRORS, isEmail, isPassword } from '../sso-helper';
import { UhfSsoService } from '../../../_services/uhfssoservice.service';
import { Subscription } from 'rxjs'; 

@Component({
  selector: 'sso-login',
  template: `<div class='sso-login'>
  <div *ngIf='localeSocialAuths' class='sso-social-items'>
    <ng-container *ngFor='let socialAuth of localeSocialAuths'>
      <div *ngIf='socialAuth.display.toLowerCase() === "true"' class='sso-social-item'>
        <button (click)='clickSocialAuth(socialAuth.providerId)'>
          <img [src]='socialAuth.icon' class='sso-social-icon' [alt]='socialAuth.label' aria-hidden='true'/>
          <div class='sso-social-title'>{{socialAuth.label}}</div>
        </button>
      </div>
    </ng-container>
  </div>
  <div class='sso-alternative'>
      <p><span>{{localeTexts?.main?.or}}</span></p>
  </div>
  <form [formGroup]="loginForm" (ngSubmit)='onSubmit()'>
    <div *ngIf='errorMessage' class='sso-error-message' role="alert">
      <mat-icon aria-hidden='true'>error</mat-icon>
      <div>{{errorMessage}}
        <a *ngIf='errorShowResetPasswordLink' (click)='clickForgotPassword($event)' href='#'>
          {{localeTexts?.errors?.userResetPassword}}
        </a>
        <ng-container *ngIf='errorCustomerSupportLocked'>
          <a [href]='localeTexts?.errors?.customerSupportLinkUrl' target='_blank'>
              {{localeTexts?.errors?.customerSupportLink}}
          </a>
          {{localeTexts?.errors?.customerSupportLinkEndLocked}}
        </ng-container>
        <ng-container *ngIf='errorCustomerSupportInactive'>
          <a [href]='localeTexts?.errors?.customerSupportLinkUrl' target='_blank'>
            {{localeTexts?.errors?.customerSupportLink}}
          </a>
          {{localeTexts?.errors?.userInactiveCombinedEnd}}
        </ng-container>
        <ng-container *ngIf='errorShowActivatedLink'>
          <a (click)='sendActivationLink($event)' href='#'>
            {{localeTexts?.errors?.userNotActivatedLink}}
          </a>
          {{localeTexts?.errors?.userNotActivatedEnd}}
        </ng-container>
        <ng-container *ngIf='errorInvalidCredentialsTermindated'>
          <a (click)='clickForgotPassword($event)' href='#'>
            {{localeTexts?.errors?.userResetPassword}}
          </a>
          {{errorMessageEnd}}
        </ng-container>
      </div>
    </div>

    <div>
      <aa-input
        [id]="'sso-login-email-input'"
        [labelText]="localeTexts?.main?.email"
        formControlName="email"
        [type]="'email'"
        [form]="loginForm"
        [autocomplete]="'email'"
        [maxLength]='64'
        [error]='errorEmail'
        ></aa-input>
    </div>
    <div style='margin: 10px 0;'>
      <aa-input
        [id]="'sso-login-password-input'"
        [labelText]="localeTexts?.main?.password"
        formControlName="password"
        [form]="loginForm"
        [type]="'password'"
        [autocomplete]="'current-password'"
        [maxLength]='16'
        [minLength]='8'
        [error]='errorPassword'
      ></aa-input>
    </div>
    <div>
      <a href='#' (click)='clickForgotPassword($event)'>
        {{localeTexts?.loginTab?.forgotPassword}}
      </a>
    </div>
    <button type='submit' #submitButton class='sso-button' [disabled]='isLoading'>
      <mat-spinner *ngIf='isLoading' class='white-spinner' [diameter]="22"></mat-spinner>
      <ng-container *ngIf='!isLoading'>
          {{localeTexts?.loginTab?.login}}
      </ng-container>
    </button>
  </form>
</div>`,
  styles: [`button{border:none;background:0 0;cursor:pointer}.sso-social-items{display:flex;flex-direction:row;justify-content:space-around}.sso-social-items .sso-social-item{display:flex;flex-direction:column;align-items:center;cursor:pointer}.sso-social-items .sso-social-item .sso-social-icon{width:50px;height:50px;border-radius:50%}.sso-row{display:flex;flex-direction:row}.sso-column{display:flex;flex:1;flex-direction:column}.sso-button{width:100%;font-size:1.18rem;background:#e10000;border-radius:4px;border:none;padding:15px;color:#fff;cursor:pointer;display:flex;justify-content:center}.sso-button:disabled{cursor:progress}ul{list-style-type:none;padding:0}.sso-error-message{box-sizing:border-box;background-color:#ffebee;padding:12px;margin:15px 0;border-radius:5px;color:#e10000;width:100%;display:flex;font-size:.9rem;align-items:flex-start}.sso-error-message div{margin-left:10px;display:block;align-self:center}.sso-error-message a{text-decoration:underline}.sso-login{padding:15px 25px;background:#fff}.sso-alternative{margin:25px 0}.sso-alternative p{width:100%;text-align:center;border-bottom:1px solid #bfc5cf;line-height:.1em;margin:10px 0}.sso-alternative p span{padding:0 5px;background:#fff}.sso-button{margin-top:10px}`]
})
export class SsoLoginComponent implements OnInit, OnChanges, OnDestroy {

  public localeSocialAuths: any;
  public allSub: Subscription[] = [];

  loginForm: FormGroup;
  errorMessage: string = '';
  errorMessageEnd: string = '';
  errorEmail: boolean = false;
  errorPassword: boolean = false;
  errorShowResetPasswordLink: boolean = false;
  errorCustomerSupportLocked: boolean = false;
  errorCustomerSupportInactive: boolean = false;
  errorShowActivatedLink: boolean = false;
  errorInvalidCredentialsTermindated: boolean = false;
  isLoading: boolean = false;

  @Input() localeTexts: any;
  @Output() changeViewComponent: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('submitButton') submitButton: ElementRef;

  constructor(private uhfSsoService: UhfSsoService) {}

  ngOnInit() {
    this.loginForm = new FormGroup({
      'email': new FormControl(null, [Validators.required, Validators.email, Validators.maxLength(64)]),
      'password': new FormControl(null, [Validators.required, Validators.minLength(8), Validators.maxLength(16)])
    });
    this.getLocaleSocialAuths();
  }

  sendActivationLink(e:Event) {
    e.preventDefault();
    let email = this.loginForm.value.email;
    this.uhfSsoService.userActivateKeys(email);
    this.uhfSsoService.setTempEmail(email);
    this.changeViewComponent.emit(VIEW_COMPONENTS.ACTIVATION);
  }

  clickForgotPassword(e:Event) {
    e.preventDefault();
    this.changeViewComponent.emit(VIEW_COMPONENTS.FORGOT_PASSWORD);
  }

  ngOnChanges() {
    this.getLocaleSocialAuths();
  }

  getLocaleSocialAuths() {
    if (!this.localeTexts) return;
    let text = this.localeTexts;
    if (!text) return;
    let socialKeys = Object.keys(text).filter((key) => key.startsWith('social'));
    this.localeSocialAuths = socialKeys.map((key) => text[key]).sort(function(a, b) {
      return a.position- b.position;
    });
  }

  clickSocialAuth(providerId:string) {
    this.uhfSsoService.loginBySocialNetwork(providerId);
  }

  onSubmit() {
    // this.submitButton.nativeElement.focus();
    this.resetErrors();
  
    const emailValue = this.loginForm.value.email;
    const passwordValue = this.loginForm.value.password;
    if (!emailValue) {
      this.setErrorMessage(ERRORS.EMPTY_EMAIL);
      return;
    }
    if (!isEmail(emailValue)) {
       this.setErrorMessage(ERRORS.INVALID_EMAIL);
      return;
    }
    if (!isPassword(passwordValue)) {
      this.setErrorMessage(ERRORS.INVALID_PASSWORD);
      return;
    }
    this.isLoading = true;
    let sub = this.uhfSsoService.loginByCredentials(
      emailValue, 
      passwordValue
    ).subscribe(
      userDetails => {
          // console.log("User loggged in susscessful", userDetails);
          if (this.uhfSsoService.shouldRetriveLoginState()) this.uhfSsoService.clearInterval();
          this.uhfSsoService.startInterval();
          this.uhfSsoService.notifyLogInStatus(true, userDetails);
      },
      error => {
          // console.log("Error in Logging in User", error);
          if (error.error.code === ERRORS.USER_TERMINATED && error.error.failedLoginAttempt < 10) {
            this.setErrorMessage(ERRORS.USER_DEACTIVATED)
          } else if (error.error.code === ERRORS.INVALID_CREDENTIALS) {
            if (error.error.failedLoginAttempt >= 10 && error.error.status === 'active') {
              this.setErrorMessage(ERRORS.INVALID_CREDENTIALS_TERMINATED);
            } else if (error.error.failedLoginAttempt % 3 === 0) {
              this.changeViewComponent.emit(VIEW_COMPONENTS.FORGOT_PASSWORD);
            } else {
              this.setErrorMessage(error.error.code);
            }
          } else {
            this.setErrorMessage(error.error.code);
          }
          this.uhfSsoService.notifyLogInStatus(false);
          this.uhfSsoService.clearInterval();
      }
    )
    sub.add(() => this.isLoading = false);
    this.allSub.push(sub);
  }

  setErrorMessage(type:string) {
    var errors = {
      [ERRORS.EMPTY_EMAIL]: () => {
        this.errorEmail = true;
        return this.localeTexts['errors']['emptyEmailAddress'];
      },
      [ERRORS.INVALID_EMAIL]: () => {
        this.errorEmail = true;
        return this.localeTexts['errors']['invalidEmail'];
      },
      [ERRORS.INVALID_USERNAME]: () => {
        this.errorEmail = true;
        return this.localeTexts['errors']['emailNotFound'];
      },
      [ERRORS.INVALID_PASSWORD]: () => {
        this.errorShowResetPasswordLink = true;
        this.errorPassword = true;
        return this.localeTexts['errors']['incorrectPassword'];
      },
      [ERRORS.INVALID_CREDENTIALS]: () => {
        this.errorEmail = true;
        this.errorPassword = true;
        return this.localeTexts['errors']['invalidEmailOrPassword'];
      },
      [ERRORS.USER_TERMINATED]: () => {
        this.errorShowResetPasswordLink = true;
        return this.localeTexts['errors']['userTerminated'];
      },
      [ERRORS.USER_LOCKED]: () => {
        this.errorCustomerSupportLocked = true;
        return this.localeTexts['errors']['userLocked'];
      },
      [ERRORS.USER_NOT_ACTIVATED]: () => {
        this.errorShowActivatedLink = true;
        return this.localeTexts['errors']['userNotActivated'];
      },
      [ERRORS.USER_DEACTIVATED]: () => {
        this.errorShowActivatedLink = true;
        return this.localeTexts['errors']['userForgotten'];
      },
      [ERRORS.USER_INACTIVE]: () => {
        this.errorCustomerSupportInactive = true;
        return this.localeTexts['errors']['userInactiveCombined'];
      },
      [ERRORS.INVALID_CREDENTIALS_TERMINATED]: () => {
        this.errorInvalidCredentialsTermindated = true;
        this.errorMessageEnd = this.localeTexts['errors']['userTerminatedNotify'].replace('{{email}}', this.loginForm.value.email);
        return this.localeTexts['errors']['userTerminated'];
      },
      [ERRORS.SERVER_ERROR]: () => {
        return this.localeTexts['errors']['serverError'];
      }
    }
    this.errorMessage = (errors[type] || errors[ERRORS.SERVER_ERROR])();
  }

  resetErrors() {
    this.errorEmail = false;
    this.errorPassword = false;
    this.errorShowResetPasswordLink = false;
    this.errorCustomerSupportLocked = false;
    this.errorCustomerSupportInactive = false;
    this.errorShowActivatedLink = false;
    this.errorInvalidCredentialsTermindated = false;
    this.errorMessage = '';
    this.errorMessageEnd = '';
  }

  ngOnDestroy() {
    for (let sub of this.allSub) {
      sub.unsubscribe();
    }
  }
}
