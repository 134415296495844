import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { FormGroup, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { RadioButtonItem } from './aa-radio-button.model';

@Component({
  selector: 'aa-radio-button',
  template: `<div class="radio-buttons">
  <div *ngFor="let item of dataList">
    <input (click)="toggleChange(item.value);" type="radio" [id]='"radio-button-"+item.value' [checked]="selectedValue == item.value ? true : null"/>
    <label [for]='"radio-button-"+item.value'>{{item.text}}</label>
  </div>
</div>`,
  styles: [`.radio-buttons{display:flex}input[type=radio]{height:23px;opacity:0;position:absolute;width:23px}label{position:relative;padding-left:30px;cursor:pointer;display:inline-block;height:25px;line-height:25px;transition:.28s;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;padding-right:15px;font-size:1rem;color:#5a5d63}input[type=radio]:checked+label:after{border:2px solid #e10;-webkit-transform:scale(1.02);transform:scale(1.02);background:#e10}label:not(:checked):after,label:not(:checked):before{border:2px solid #5a5a5a;border-radius:50%;content:"";position:absolute;left:0;top:0;margin:4px;width:16px;height:16px;z-index:0;transition:.28s}`],
  providers: [{ 
    provide: NG_VALUE_ACCESSOR, 
    useExisting: forwardRef(() => AaRadioButtonComponent), 
    multi: true 
  }]
})
export class AaRadioButtonComponent implements OnInit {
  private _value: any = '';
  _onTouchedCallback: (_: any) => {};
  _onChangeCallback: (_: any) => {};

  @Input() groupName: string;
  @Input() id: string;
  @Input() dataList: RadioButtonItem[] = [];
  @Input() form: FormGroup;
  @Input() formControlName: string;

  get selectedValue(): any { return this._value; };
    @Input() set selectedValue(v: any) {
      if (v !== this._value) {
        this._value = v;
        this._onChangeCallback(v);
      }
  }

  constructor() { }

  ngOnInit() {
  }

  toggleChange(value:any) {
    this.selectedValue = value;
    this._value = value;
  }

  writeValue(value: any) {
    this._value = value;
  }


  registerOnChange(fn: any) {
    this._onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this._onTouchedCallback = fn;
  }

}

