import { Component, OnInit,Input } from '@angular/core';

@Component({
  selector: 'aa-tab',
  template: `<div [hidden] = "!active" role="tabpanel" [attr.aria-labelledby]= "title">
  <ng-content></ng-content>
</div>
`,
  styles: [``]
})
export class TabComponent {

  @Input() title:string;
  @Input() active:boolean = false;
  @Input() disabled :boolean = false;
  public _isFocused:boolean = false;

  getLabel():string{
    return this.title;
  }
}
