import { Component, OnInit, Input, Output, EventEmitter, OnChanges, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { VIEW_COMPONENTS, ERRORS, isEmail, isPassword } from '../sso-helper';
import { UhfSsoService } from '../../../_services/uhfssoservice.service';

@Component({
  selector: 'sso-signup',
  template: `<div class='sso-signup'>
  <div *ngIf='localeSocialAuths' class='sso-social-items'>
    <ng-container *ngFor='let socialAuth of localeSocialAuths'>
      <div *ngIf='socialAuth.display.toLowerCase() === "true"' class='sso-social-item'>
        <button (click)='clickSocialAuth(socialAuth.providerId)'>
          <img [src]='socialAuth.icon' class='sso-social-icon' [alt]='socialAuth.label' aria-hidden='true'/>
          <div class='sso-social-title'>{{socialAuth.label}}</div>
        </button>
      </div>
    </ng-container>
  </div>
  <div class='sso-alternative'>
      <p><span>{{localeTexts?.main?.or}}</span></p>
  </div>
  <form [formGroup]="form" (ngSubmit)='onSubmit()'>
    <div *ngIf='errorMessage' class='sso-error-message' role="alert">
      <mat-icon aria-hidden='true'>error</mat-icon>
      <div>{{errorMessage}}
        <ng-container *ngIf='errorCustomerSupportLocked'>
          <a [href]='localeTexts?.errors?.customerSupportLinkUrl' target='_blank'>
              {{localeTexts?.errors?.customerSupportLink}}
          </a>
          {{localeTexts?.errors?.customerSupportLinkEndLocked}}
        </ng-container>
        <ng-container *ngIf='errorCustomerSupportInactive'>
          <a [href]='localeTexts?.errors?.customerSupportLinkUrl' target='_blank'>
            {{localeTexts?.errors?.customerSupportLink}}
          </a>
          {{localeTexts?.errors?.userInactiveCombinedEnd}}
        </ng-container>
        <ng-container *ngIf='errorShowActivatedLink'>
          <a (click)='sendActivationLink($event)' href='#'>
            {{userNotActivatedLink}}
          </a>
          {{userNotActivatedEnd}}
        </ng-container>
        <a *ngIf='errorShowLoginLink' (click)='loginView($event)' href='#'>
          {{localeTexts?.errors?.loginWithEmail}}
        </a>
      </div>
    </div>
    <div>
      <aa-input
        [id]="'sso-signup-email-input'"
        [labelText]="localeTexts?.main?.email"
        formControlName="email"
        [form]="form"
        [type]="'email'"
        [autocomplete]="'email'"
        [maxLength]='64'
        [error]='errorEmail'
        ></aa-input>
    </div>
    <div style='margin: 10px 0;'>
      <aa-input
        [id]="'sso-signup-password-input'"
        [labelText]="localeTexts?.main?.password"
        formControlName="password"
        [form]="form"
        [type]="'password'"
        [autocomplete]="'new-password'"
        [maxLength]='16'
        [minLength]='8'
        [error]='errorPassword'
      ></aa-input>
    </div>
    <button type='submit' #submitButton class='sso-button' [disabled]='isLoading'>
      <mat-spinner *ngIf='isLoading' class='white-spinner' [diameter]="22"></mat-spinner>
      <ng-container *ngIf='!isLoading'>
          {{localeTexts?.signupTab?.signupForFree}}
      </ng-container>
    </button>
  </form>
  <div>
    <p class='sso-agree' [innerHTML]='termsAndConditions'></p>
  </div>
</div>`,
  styles: [`button{border:none;background:0 0;cursor:pointer}.sso-social-items{display:flex;flex-direction:row;justify-content:space-around}.sso-social-items .sso-social-item{display:flex;flex-direction:column;align-items:center;cursor:pointer}.sso-social-items .sso-social-item .sso-social-icon{width:50px;height:50px;border-radius:50%}.sso-row{display:flex;flex-direction:row}.sso-column{display:flex;flex:1;flex-direction:column}.sso-button{width:100%;font-size:1.18rem;background:#e10000;border-radius:4px;border:none;padding:15px;color:#fff;cursor:pointer;display:flex;justify-content:center}.sso-button:disabled{cursor:progress}ul{list-style-type:none;padding:0}.sso-error-message{box-sizing:border-box;background-color:#ffebee;padding:12px;margin:15px 0;border-radius:5px;color:#e10000;width:100%;display:flex;font-size:.9rem;align-items:flex-start}.sso-error-message div{margin-left:10px;display:block;align-self:center}.sso-error-message a{text-decoration:underline}.sso-signup{padding:15px 25px;background:#fff}.sso-alternative{margin:25px 0}.sso-alternative p{width:100%;text-align:center;border-bottom:1px solid #bfc5cf;line-height:.1em;margin:10px 0}.sso-alternative p span{padding:0 5px;background:#fff}.sso-agree{margin-top:20px;font-size:.9rem;color:#5a5d63}`]
})
export class SsoSignupComponent implements OnInit, OnChanges {

  public localeSocialAuths: any;

  form: FormGroup;
  errorMessage: string = '';
  errorEmail: boolean = false;
  errorPassword: boolean = false;
  errorCustomerSupportLocked: boolean = false;
  errorCustomerSupportInactive: boolean = false;
  errorShowActivatedLink: boolean = false;
  errorShowLoginLink: boolean = false;
  isLoading: boolean = false;
  userNotActivatedLink: any;
  userNotActivatedEnd: any;

  public termsAndConditions:SafeHtml;

  @Input() errorType: string;
  @Input() localeTexts: any;
  @Output() changeViewComponent: EventEmitter<string> = new EventEmitter<string>();
  @Output() onSignUpSubmit: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

  @ViewChild('submitButton') submitButton: ElementRef;

  constructor(
    private sanitizer: DomSanitizer,
    private uhfSsoService: UhfSsoService
  ) { }

  ngOnInit() {
    this.form = new FormGroup({
      'email': new FormControl(null, [Validators.required, Validators.email]),
      'password': new FormControl(null)
    });
    this.getLocaleSocialAuths();
    this.getTermsAndConditions();
    if (this.errorType) {
      this.form.controls.email.setValue(this.uhfSsoService.getTempEmail());
      this.setErrorMessage(this.errorType)
    }
  }

  resetPasswordView(e:Event) {
    e.preventDefault();
    this.changeViewComponent.emit(VIEW_COMPONENTS.FORGOT_PASSWORD);
  }

  loginView(e:Event) {
    e.preventDefault();
    this.changeViewComponent.emit(VIEW_COMPONENTS.LOGIN);
  }

  sendActivationLink(e:Event) {
    e.preventDefault();
    let email = this.form.value.email;
    this.uhfSsoService.userActivateKeys(email);
    this.uhfSsoService.setTempEmail(email);
    this.changeViewComponent.emit(VIEW_COMPONENTS.ACTIVATION);
  }

  clickForgotPassword(e:Event) {
    e.preventDefault();
    this.changeViewComponent.emit(VIEW_COMPONENTS.FORGOT_PASSWORD);
  }

  ngOnChanges() {
    this.getLocaleSocialAuths();
    this.getTermsAndConditions();
  }

  getTermsAndConditions() {
    try {
      let terms = this.localeTexts['signupTab']['footNote'];
      this.termsAndConditions = this.sanitizer.bypassSecurityTrustHtml(terms)
    } catch (err) {};
  }

  getLocaleSocialAuths() {
    if (!this.localeTexts) return;
    let text = this.localeTexts;
    if (!text) return;
    let socialKeys = Object.keys(text).filter((key) => key.startsWith('social'));
    this.localeSocialAuths = socialKeys.map((key) => text[key]).sort(function(a, b) {
      return a.position- b.position;
    });
  }

  clickSocialAuth(providerId:string) {
    // console.log(providerId)
    this.uhfSsoService.loginBySocialNetwork(providerId);
  }

  onSubmit() {
    this.submitButton.nativeElement.focus();
    this.resetErrors();
    const emailValue = this.form.value.email;
    const passwordValue = this.form.value.password;
    if (!emailValue) {
      this.setErrorMessage(ERRORS.EMPTY_EMAIL);
      return;      
    }
    if (!isEmail(emailValue)) {
       this.setErrorMessage(ERRORS.INVALID_EMAIL);
      return;
    }
    if (!isPassword(passwordValue)) {
      this.setErrorMessage(ERRORS.INVALID_PASSWORD);
      return;
    }
    this.isLoading = true;

    this.onSignUpSubmit.emit(this.form);
  }

  setErrorMessage(type:string) {
    var errors = {
      [ERRORS.EMPTY_EMAIL]: () => {
        this.errorEmail = true;
        return this.localeTexts['errors']['emptyEmailAddress'];
      },
      [ERRORS.INVALID_EMAIL]: () => {
        this.errorEmail = true;
        return this.localeTexts['errors']['invalidEmail'];
      },
      [ERRORS.INVALID_USERNAME]: () => {
        this.errorEmail = true;
        return this.localeTexts['errors']['emailNotFound'];
      },
      [ERRORS.INVALID_PASSWORD]: () => {
        this.errorPassword = true;
        return this.localeTexts['errors']['incorrectPasswordSignup'];
      },
      [ERRORS.USER_LOCKED]: () => {
        this.errorCustomerSupportLocked = true;
        return this.localeTexts['errors']['userLocked'];
      },
      [ERRORS.USER_NOT_ACTIVATED]: () => {
        this.errorShowActivatedLink = true;
        return this.localeTexts['errors']['userNotActivated'];
      },
      [ERRORS.USER_INACTIVE]: () => {
        this.errorCustomerSupportInactive = true;
        return this.localeTexts['errors']['userInactiveCombined'];
      },
      [ERRORS.USER_ALREADY_EXISTS]: () => {
        this.errorEmail = true;
        this.errorShowLoginLink = true;
        return this.localeTexts['errors']['emailExists'].replace("{{email}}", this.form.value.email);
      },
      [ERRORS.SERVER_ERROR]: () => {
        return this.localeTexts['errors']['serverError'];
      },
    }
    this.errorMessage = (errors[type] || errors[ERRORS.SERVER_ERROR])();
  }

  resetErrors() {
    this.errorType = '';
    this.errorEmail = false;
    this.errorPassword = false;
    this.errorCustomerSupportLocked = false;
    this.errorCustomerSupportInactive = false;
    this.errorShowActivatedLink = false;
    this.errorShowLoginLink = false;
    this.errorMessage = '';
  }
}
