import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from '../../_components/aa-header/aa-header.component';
import { LoginComponent } from '../../_components/aa-login/login.component';
import { MatTabsModule, MatIconModule, MatSidenavModule, MatDialogModule, MatToolbarModule, MatListModule, MAT_DIALOG_DATA, MatDialogRef, MatProgressSpinner, MatSpinner, MatProgressSpinnerModule } from '@angular/material';
import { LocaleService } from '../../_services/locale.service';
import { UserdataService } from '../../_services/userdata.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FooterModule } from '../footer/footer.module';
import { DOMService } from '../../_services/dom.service';
import { AaNewHamburgerComponent } from '../../_components/aa-new-hamburger/aa-new-hamburger.component';
import { AaNewSidenavComponent } from '../../_components/aa-new-sidenav/aa-new-sidenav.component';
import { AaSecondarynavComponent } from '../../_components/aa-secondarynav/aa-secondarynav.component';
import { ComponentSettingService } from '../../_services/component-setting.service';
import { ReactiveFormsModule, FormsModule }   from '@angular/forms';
import { AaSsoComponent } from '../../_components/aa-sso/aa-sso.component'
import { TabsComponent } from '../../shared/aa-tabs/aa-tabs.component';
import { TabComponent } from '../../shared/aa-tab/aa-tab.component';
import { SsoLoginComponent } from '../../_components/aa-sso/sso-login/sso-login.component';
import { SsoSignupComponent } from '../../_components/aa-sso/sso-signup/sso-signup.component';
import { ForgotPasswordComponent } from '../../_components/aa-sso/forgot-password/forgot-password.component';
import { SsoFooterComponent } from '../../_components/aa-sso/sso-footer/sso-footer.component';
import { SsoUserDetailsComponent } from '../../_components/aa-sso/sso-user-details/sso-user-details.component';
import { AaInputComponent } from '../../shared/aa-input/aa-input.component';
import { AaRadioButtonComponent } from '../../shared/aa-radio-button/aa-radio-button.component';
import { SsoSidebarComponent } from '../../_components/aa-sso/sso-sidebar/sso-sidebar.component';
import { SsoActivationComponent } from '../../_components/aa-sso/sso-activation/sso-activation.component';
//gangesh
import { SsoApiCalls } from '../../_services/sso-api-call.service';
import { BaseHttpService } from '../../_services/base-http-calls.service';
import { HttpClientModule } from '@angular/common/http';
import { UhfSsoService } from '../../_services/uhfssoservice.service';
import { TooltipService } from '../../_services/tooltip.service';
import { TooltipModule } from '../tooltip/tooltip.module';

@NgModule({
  imports: [
    CommonModule,
    MatTabsModule,
    MatIconModule,
    MatSidenavModule,
    MatDialogModule,
    MatToolbarModule,
    MatListModule,
    BrowserAnimationsModule,
    FooterModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    TooltipModule
  ],
  declarations: [
    HeaderComponent,
    LoginComponent,
    AaNewHamburgerComponent,
    AaNewSidenavComponent,
    AaSecondarynavComponent,
    AaSsoComponent,
    TabsComponent,
    TabComponent,
    SsoLoginComponent,
    SsoSignupComponent,
    SsoFooterComponent,
    SsoUserDetailsComponent,
    AaInputComponent,
    ForgotPasswordComponent,
    AaRadioButtonComponent,
    SsoSidebarComponent,
    SsoActivationComponent
  ],
  providers: [
    UserdataService,
    LocaleService,
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
    DOMService,
    ComponentSettingService,
    SsoApiCalls,
    BaseHttpService,
    UhfSsoService,
    TooltipService
  ],
  exports: [
    HeaderComponent
  ],
  entryComponents: [
    LoginComponent,
    AaNewHamburgerComponent,
    AaNewSidenavComponent
  ]
})
export class HeaderModule { }
