import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserdataService } from '../../_services/userdata.service';
import { LocaleService } from '../../_services/locale.service';
import { mergeMap, map, flatMap, catchError } from 'rxjs/operators';
import { of, Subscription } from 'rxjs';
import { ComponentSettingService } from '../../_services/component-setting.service';

@Component({
  selector: 'app-aa-new-sidenav',
  template: `<div class="side-wrap" (click)="closeNewSideNav($event)" [attr.data-locale]="currentLocale">
  <div class="side-area">
    <div class="side-body">

      <div class="sideLogin">
        <div class="banner" *ngIf="localeTexts.length" [style.backgroundImage]="'url('+ localeTexts[0].headerBanner.imageURL +')'">
          <div class="bigLogoLayer">
            <img src="{{localeTexts[0].bigLoyaltyLogo.imageURL}}" alt="Big Loyalty Logo">
            <div class="userName">
              <span *ngIf="loggedIn">{{userName}}</span>
            </div>
          </div>
        </div>

        <div class="login">
          <aa-sso-widget *ngIf="!loggedIn" [isMobileView]='true'></aa-sso-widget>
          <login-widget *ngIf="loggedIn"></login-widget>
        </div>
      </div>

    </div>
  </div>

  <div class="closeMark cl">
    <div class="x cl">
      <i class="material-icons cl">clear</i>
    </div>
    <div class="menu cl">
        <i class="material-icons cl">menu</i>
    </div>
  </div>

</div>`,
  styles: [`.side-wrap{position:fixed;top:0;width:100%;height:100vh;background-color:#fff;z-index:10;-webkit-transform:translateX(-100%);transform:translateX(-100%);float:left;overflow-y:scroll}.side-wrap .side-area{position:relative;width:calc(100% - 2.625rem);height:100%;float:right;overflow-y:scroll}.side-wrap .side-area .side-body{position:relative;width:100%;height:100%;float:left}.side-wrap .side-area .side-body .sideLogin{height:100%;background:#f2f3f6}.side-wrap .side-area .side-body .banner{min-height:6.5rem}.side-wrap .side-area .side-body .banner .bigLogoLayer{background-color:rgba(0,0,0,.5);min-height:6.5rem;padding-left:1.5rem}.side-wrap .side-area .side-body .banner .bigLogoLayer img{margin-top:1.313rem;padding-left:.625rem}.side-wrap .side-area .side-body .banner .bigLogoLayer .userName{position:relative;color:#fff;left:4.5rem;bottom:.3125rem;font-weight:400;width:150px}.side-wrap.side-in{-webkit-animation:.2s ease-in-out forwards rtlMoveIn;animation:.2s ease-in-out forwards rtlMoveIn}.side-wrap.side-out{-webkit-animation:.3s ease-in-out forwards rtlMoveOut;animation:.3s ease-in-out forwards rtlMoveOut}.closeMark{width:2.625rem;height:100%;position:absolute;background-color:rgba(0,0,0,.73);top:0}.closeMark .x{color:#fff;text-align:center;margin-top:18.75rem}.closeMark .x i{font-size:1.875rem}.closeMark .menu{position:absolute;top:0;padding:.6rem 0;background-color:rgba(255,255,255,.1);width:100%;text-align:center}@media screen and (min-width:601px) and (max-width:1024px){.side-wrap{background-color:rgba(0,0,0,.73)}.side-wrap .side-area{width:25rem;background-color:#fff}.closeMark{background-color:transparent;right:25rem}.closeMark .menu{display:none}}@media screen and (min-width:1025px){.side-wrap.side-in{display:none}}`]
})
export class AaNewSidenavComponent implements OnInit, OnDestroy {

  public userName: string = "";
  public loggedIn: boolean = false;
  public loggedindata: any;
  public currentLocale: string;
  public localeTexts: Array<any> = [];
  public isLoginReloadRequired: boolean = false;
  public allSub: Subscription[] = [];

  constructor(
    private userDataService: UserdataService, 
    private localeService: LocaleService,
    private componentSettingService: ComponentSettingService
  ) { }

  ngOnInit() {

    // User login change lookup for user data
    let ssoLSub = this.userDataService.ssoLoginChange$.subscribe(userData => {

      // If SSO response received as success
      if (userData != null && userData.status) {

        // This is normal flow
        if (userData.ssoData.firstName || userData.ssoData.username) {

          this.loggedIn = userData.status;
          this.loggedindata = {
            'firstname': userData['ssoData']['firstName'],
            'email': userData['ssoData']['username']
          };
          this.userName = this.prepareFirstName(this.loggedindata);
        }

      } else if (userData != null && !userData.status) {

        // If SSO response received as failure
        this.loggedIn = false;
      }

    })
    this.allSub.push(ssoLSub);

    // Look for locale change
    let locSub = this.userDataService.$localeChange
      .pipe(
        mergeMap(locale => {

          if (this.currentLocale != locale) {
            this.currentLocale = locale;
            return this.localeService.loadRemoteLocale(['main'], this.userDataService.getEnvironment())
          } else {
            return of([])
          }

        }),
        map(resp => {

          this.onLocaleLoad(resp);
        }),
        catchError(err => {

          // Fallback to en-gb
          let resp = this.localeService.getFbLocaleSections(['main']);
          this.onLocaleLoad(resp);
          return of([])
        })
      )
      .subscribe();
    this.allSub.push(locSub);

    let ssoWLSub = this.userDataService.ssoWidgetLoaded$.subscribe((status) => {

      // If SSO response received
      if (status && window.screen.width <= 1024) {
        this.isLoginReloadRequired = false;
        setTimeout(() => {
          this.isLoginReloadRequired = true;
        }, 0)
      }
    })
    this.allSub.push(ssoWLSub);

  }

  onLocaleLoad(resp) {
    if (resp.length) {
      this.localeTexts.splice(0, this.localeTexts.length);
      resp.forEach(r => {
        this.localeTexts.push(r)
      });
    }
  }

  closeNewSideNav(e) {
    if (e.target.classList.contains('side-wrap') || e.target.classList.contains('cl')) {
      this.componentSettingService.SSOmodalOpenClose(false);
    }
  }

  prepareFirstName(input: any) {

    let fName;
    if (input.firstname) {
      fName = input.firstname.length >= 15 ? input.firstname.substring(0, 12) + '...' : input.firstname;
    } else if (input.email) {
      fName = input.email.length >= 15 ? input.email.substring(0, 12) + '...' : input.email;
    }

    if (fName && this.currentLocale && this.currentLocale.toLowerCase() === 'ja-jp') {
      fName = fName + ' ãã';
    }

    return fName ? fName : 'NA';
  }

  ngOnDestroy() {
    for (let sub of this.allSub) {
      sub.unsubscribe();
    }
  }
}
