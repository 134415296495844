import { UserdataService } from "./userdata.service";
import { Injectable } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { switchMap } from "rxjs/operators";
import { Subject, of, Observable, Subscription } from "rxjs";
import { UhfSsoService } from "./uhfssoservice.service";
import { BaseHttpService } from "./base-http-calls.service";
import { Endpoints } from '../_config/endpoints';
import { ToolTipModel, TooltipDataFromBucketModel, TooltipDefaultMessage } from '../_utilities/constant_variables';
// import { type } from "os";
import { not } from "@angular/compiler/src/output/output_ast";

@Injectable()
export class TooltipService {

    public dataInToolTip = new Subject<ToolTipModel>();
    public tooltipRequested = new Subject<boolean>();
    whichDataToSHow: "urlmessage1" | "urlmessage2" | "urlmessage3" | "default" | "custom" | "" = "";
    state: string = "";
    defaultMessageStructure: TooltipDefaultMessage;
    dataRendered: ToolTipModel;
    productionEnvironment: boolean = false;
    tooltipDataFromBucket: TooltipDataFromBucketModel | null;
    loggedIn: boolean = false;

    constructor(
        private activatedRoute: ActivatedRoute,
        private userDataService: UserdataService,
        private uhfSsoService: UhfSsoService,
        private baseHttpervice: BaseHttpService
    ) {
    }


    setEnvironment = (env: boolean) => this.productionEnvironment = env;



    takeDataAsPerLoginAndTypeOfMessageAndPropagate = () => {
        if (!this.tooltipDataFromBucket) return;
        if (this.loggedIn) {
            if ((this.whichDataToSHow == 'urlmessage1' && (this.state == 'login' || this.state == "")) || (this.whichDataToSHow == 'default' && this.defaultMessageStructure && this.defaultMessageStructure.login == 'message1'))
                this.dataRendered = this.tooltipDataFromBucket["login"].filter(data => data["message1"])[0]['message1'][0];//login message1
            else if ((this.whichDataToSHow == 'urlmessage2' && (this.state == 'login' || this.state == "")) || (this.whichDataToSHow == 'default' && this.defaultMessageStructure && this.defaultMessageStructure.login == 'message2'))
                this.dataRendered = this.tooltipDataFromBucket["login"].filter(data => data["message2"])[0]['message2'][0];//login message2
            else if ((this.whichDataToSHow == 'urlmessage3' && (this.state == 'login' || this.state == "")) || (this.whichDataToSHow == 'default' && this.defaultMessageStructure && this.defaultMessageStructure.login == 'message3'))
                this.dataRendered = this.tooltipDataFromBucket["login"].filter(data => data["message3"])[0]['message3'][0];//login message3
        }
        else {
            if ((this.whichDataToSHow == 'urlmessage1' && (this.state == 'nonlogin' || this.state == "")) || (this.whichDataToSHow == 'default' && this.defaultMessageStructure && this.defaultMessageStructure.nonLogin == 'message1'))
                this.dataRendered = this.tooltipDataFromBucket["nonLogin"].filter(data => data["message1"])[0]['message1'][0];//non login message1
            else if ((this.whichDataToSHow == 'urlmessage2' && (this.state == 'nonlogin' || this.state == "")) || (this.whichDataToSHow == 'default' && this.defaultMessageStructure && this.defaultMessageStructure.nonLogin == 'message2'))
                this.dataRendered = this.tooltipDataFromBucket["nonLogin"].filter(data => data["message2"])[0]['message2'][0];//non login message2
            else if ((this.whichDataToSHow == 'urlmessage3' && (this.state == 'nonlogin' || this.state == "")) || (this.whichDataToSHow == 'default' && this.defaultMessageStructure && this.defaultMessageStructure.nonLogin == 'message3'))
                this.dataRendered = this.tooltipDataFromBucket["nonLogin"].filter(data => data["message3"])[0]['message3'][0];//non login message2
        }
        if (this.state == 'nonlogin' && this.loggedIn == true)
            this.dataRendered = null;
        this.dataInToolTip.next(this.dataRendered);
    }



    startTooltip = (login) => {
        this.loggedIn = login;
        return this.activatedRoute.queryParams
            .pipe(
                switchMap(
                    (params) => {
                        if (params['state'] == 'login')
                            this.state = "login";
                        else if (params['state'] == 'nonlogin')
                            this.state = "nonlogin";

                        if (params['type'] == 'message1')
                            this.whichDataToSHow = "urlmessage1";
                        if (params['type'] == 'message2')
                            this.whichDataToSHow = "urlmessage2";
                        return this.userDataService.$localeChange;
                    }
                ),
                switchMap(
                    (locale) => {
                        return this.getTooltipDataFromBucket(locale);
                    }
                )
            )
        // .subscribe(
        //     (dataFromBucket: TooltipDataFromBucketModel) => {
        //         this.tooltipDataFromBucket = dataFromBucket;
        //         console.log("reaching in subscription",this.tooltipDataFromBucket);
        //         this.takeDataAsPerLoginAndTypeOfMessageAndPropagate();
        //         this.setDefaultToolTipMessage({"message":"message2"})
        //     },
        //     (error) => {
        //         this.tooltipDataFromBucket = null;
        //         console.log("error in http call for tooltip");
        //     })

    }


    getTooltipDataFromBucket = (locale: string) => {
        if (this.productionEnvironment)
            return this.baseHttpervice.get(Endpoints.sections['tooltip'].replace("{locale}", locale));
        else
            return this.baseHttpervice.get(Endpoints.stgSections['tooltip'].replace("{locale}", locale));
    }



    setDefaultToolTipMessage = (tooltipDefaultModel: TooltipDefaultMessage) => {
        if (Object.keys(tooltipDefaultModel).length == 0)
            return;
        // console.log('reaching');
        this.whichDataToSHow = "default";
        this.defaultMessageStructure = tooltipDefaultModel;
        this.tooltipRequested.next(true);
        // this.takeDataAsPerLoginAndTypeOfMessageAndPropagate();
    }

    setCustomTooltipMessage = (tooltipModel: ToolTipModel) => {
        if (this.whichDataToSHow != "")
            return;
        this.whichDataToSHow = "custom";
        this.dataRendered = tooltipModel
        this.dataInToolTip.next(this.dataRendered);
    }

    removeTooltip() {
        this.tooltipRequested.next(false);
    }
}