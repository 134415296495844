import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs'
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Endpoints } from '../_config/endpoints';

//declare var aaWidget: any;

@Injectable()
export class UserdataService {

  public UHFEnvironment: string = 'dev';
  public lastLoginState?: boolean = false;
  public lastLoginSecNavState?: boolean = false;
  public UHFClientId: string = null;

  public getuserdata = new BehaviorSubject<boolean>(true);
  private loggedindata = this.getuserdata.asObservable();
  constructor(
    private http: HttpClient
  ) { }

  getUserDetails(request: any) {
    this.getuserdata.next(request);
  }

  // Locale change observable
  private localeChangeSub = new BehaviorSubject<string>(this.getCookie('uhfLocale') ? this.getCookie('uhfLocale') : 'en-gb');
  public $localeChange = this.localeChangeSub.asObservable();
  public updateLocaleSubject(newLocale) {
    if (newLocale) {
      // setTimeout(() => {
      this.setCookie('uhfLocale', newLocale.toLowerCase(), 30)
      this.localeChangeSub.next(newLocale.toLowerCase());
      // }, 0)
    }
    // }
  }

  // Currency change observable
  private currChangeSub = new BehaviorSubject<string>(this.getCookie('uhfCurrency') ? this.getCookie('uhfCurrency') : 'USD');
  public $currencyChange = this.currChangeSub.asObservable();
  public updateCurrencySubject(newCurr) {
    if (newCurr) {
      // setTimeout(() => {
      this.setCookie('uhfCurrency', newCurr.toUpperCase(), 30)
      this.currChangeSub.next(newCurr);
      // }, 0)
    }
  }

  // SSO Widget load observable
  private ssoLoginSub = new BehaviorSubject<any>(null);
  public ssoLoginChange$ = this.ssoLoginSub.asObservable();
  public updateSsoLoginSubject(status) {
    // console.log("i am in",status)
    this.ssoLoginSub.next(status);
  }

  // Big Points call observer
  private bigPointsSub = new BehaviorSubject<any>(localStorage.getItem('bp') ? localStorage.getItem('bp') : 0);
  public bigPointsChange$ = this.bigPointsSub.asObservable();
  public updateBigPointsSubject(points) {
    localStorage.setItem('bp', points);
    this.bigPointsSub.next(points);
  }

  // When SSO scripts 
  private ssoWidgetSub = new BehaviorSubject<any>(false);
  public ssoWidgetLoaded$ = this.ssoWidgetSub.asObservable();
  public updateSSSWidgetLoadedSubject(status) {
    this.ssoWidgetSub.next(status);
  }

  // When SSO scripts 
  private ssoWidgetSyncSub = new BehaviorSubject<any>(false);
  public ssoWidgetSync$ = this.ssoWidgetSyncSub.asObservable();
  public updateSSSWidgetSyncSubject(status) {
    this.ssoWidgetSyncSub.next(status);
  }

  // PWA Loaded subscription
  private pwaLoadSub = new Subject<any>();
  public pwaLoaded$ = this.pwaLoadSub.asObservable();
  public isPwaWrap(status) {
    this.pwaLoadSub.next(status);
  }


  // Get balance
  public getBigPoints(opts) {

    const headers = new HttpHeaders({
      "user-id": opts['user-id'],
      "x-aa-client-id": opts['x-aa-client-id'],
      "authorization": opts['authorization'],
      "x-api-key": opts['x-api-key']
    });

    return this.http.get(Endpoints.getBigPoints(this.getEnvironment()), { headers })
  }

  // Set UHF-SSO environment details
  public setEnvironment(env?: string) {
    let envLow = env ? env.toLowerCase() : 'dev';
    if (envLow === 'dev' || envLow === 'sit' || envLow === 'uat' || envLow === 'prod' || envLow === 'nav2') {
      this.UHFEnvironment = envLow;
    }
  }

  // Get UHF-SSO environment details
  public getEnvironment() {
    return this.UHFEnvironment;
  }

  public setUhfClientId(clientId: string) {
    return this.UHFClientId = clientId;
  }

  public getUhfClientId() {
    return this.UHFClientId;
  }

  /* -- Utils -- */
  setCookie(name, value, days?: any) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }

  getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return;
  }
}
