import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ComponentSettingService } from '../../_services/component-setting.service';
import { KEYS } from '../../_utilities/constant_variables';

const INTERACTIVE_KEYCODES_FOCUS = [
  KEYS.enter, 
  KEYS.esc, 
  KEYS.space, 
  KEYS.leftArrow, 
  KEYS.upArrow, 
  KEYS.rightArrow, 
  KEYS.downArrow
];

@Component({
  selector: 'aa-focus-outline',
  template: `<div #aaFocus id="aa-focus" [ngClass]="{'aa-focus-visible': focusOutline}"></div>`,
  styles: [`#aa-focus{position:absolute;margin:0;top:-100px;background:0 0;transition-property:left,top,width,height,opacity;transition-timing-function:cubic-bezier(0,.2,0,1);visibility:hidden;pointer-events:none;display:none}#aa-focus.aa-focus-visible{visibility:visible;z-index:10000000;display:block}#aa-focus:after{content:'';position:absolute;top:-1px;left:-1px;bottom:-1px;right:-1px;border-radius:4px;border:1px solid #fff;box-shadow:0 0 20px 0 rgba(0,0,0,.35);-moz-box-shadow:0 0 20px 0 rgba(0,0,0,.35);-webkit-box-shadow:0 0 20px 0 rgba(0,0,0,.35)}`],
  host: {
    '(document:click)': 'hideFocusOutline()',
    '(document:keydown)': 'onKeyDown($event)'
  }
})
export class AaFocusOutlineComponent implements OnInit {

  public focusOutline: boolean = false;
  @ViewChild('aaFocus') aaFocus: ElementRef;

  constructor(private compSetting: ComponentSettingService) { }

  ngOnInit() {
    this.allowManualTrigger();
  }

  onKeyDown(event: KeyboardEvent) {
    if (event.keyCode === KEYS.tab) {
      this.showFocusOutline();
      this.updateFocusOutline();
    } else if (this.focusOutline && INTERACTIVE_KEYCODES_FOCUS.indexOf(event.keyCode) >= 0) {
      this.updateFocusOutline();
    }
  }

  updateFocusOutline(manualFocusElement = undefined) {
    if (manualFocusElement) {
      manualFocusElement.focus();
    }
    if (!this.focusOutline) return;
    setTimeout(() => {
      let aaFocusEl = this.aaFocus.nativeElement;
      let activeElement = document.activeElement;
      if (!activeElement || activeElement.tagName === "BODY" || activeElement.tagName === "HTML") {
        this.hideFocusOutline();
        return;
      }
      let scrollX = window.scrollX || window.pageXOffset;
      let scrollY = window.scrollY || window.pageYOffset;
      let focusableElClientRect = activeElement.getBoundingClientRect();
      aaFocusEl.style.left = (scrollX + focusableElClientRect.left).toString() + 'px';
      aaFocusEl.style.top = (scrollY + focusableElClientRect.top).toString() + 'px';
      aaFocusEl.style.width = focusableElClientRect.width + 'px';
      aaFocusEl.style.height = focusableElClientRect.height + 'px';
      let transitionDurationValue = this.focusOutline ? '0.15s' : '0s';
      aaFocusEl.style.transitionDuration = transitionDurationValue;
    }, 1)
  }

  showFocusOutline() {
    this.focusOutline = true;
  }

  hideFocusOutline() {
    this.focusOutline = false;
  }

  // Hack method, if apps finding issues with automatic element focus outline
  allowManualTrigger() {
    this.compSetting.UHFoutlineTrigger$.subscribe((element: HTMLElement) => {
      this.updateFocusOutline(element);
    })
  }
}
