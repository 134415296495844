import { Component, OnInit, OnDestroy } from '@angular/core';
import { of, Subscription } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { LocaleService } from '../../../_services/locale.service';
import { UserdataService } from '../../../_services/userdata.service';

@Component({
  selector: 'sso-footer',
  template: `<div *ngIf='localeTexts?.mainNav' class='sso-footer'>
  <div>{{localeTexts?.mainNav[0]?.headline}}</div>
  <div class='sso-footer-links'>
    <div *ngFor='let item of localeTexts?.mainNav[1]?.content'>
      <a [href]='item.url' [target]='item?.newTab?.toLowerCase() === "true" ? "_blank" : "_self"'>
        {{item.text}}
      </a>
    </div>
  </div>
</div>`,
  styles: [`button{border:none;background:0 0;cursor:pointer}.sso-social-items{display:flex;flex-direction:row;justify-content:space-around}.sso-social-items .sso-social-item{display:flex;flex-direction:column;align-items:center;cursor:pointer}.sso-social-items .sso-social-item .sso-social-icon{width:50px;height:50px;border-radius:50%}.sso-row{display:flex;flex-direction:row}.sso-column{display:flex;flex:1;flex-direction:column}.sso-button{width:100%;font-size:1.18rem;background:#e10000;border-radius:4px;border:none;padding:15px;color:#fff;cursor:pointer;display:flex;justify-content:center}.sso-button:disabled{cursor:progress}ul{list-style-type:none;padding:0}.sso-error-message{box-sizing:border-box;background-color:#ffebee;padding:12px;margin:15px 0;border-radius:5px;color:#e10000;width:100%;display:flex;font-size:.9rem;align-items:flex-start}.sso-error-message div{margin-left:10px;display:block;align-self:center}.sso-error-message a{text-decoration:underline}.sso-footer{padding:15px 25px;background:#f2f3f6;border-top:1px solid #bfc5cf;font-size:.9rem}.sso-footer .sso-footer-links{display:flex;margin-top:15px;flex-direction:row;justify-content:space-between}@media only screen and (max-width:1024px){.sso-footer{margin-top:1rem}.sso-footer .sso-footer-links{flex-direction:column;justify-content:unset}}`]
})
export class SsoFooterComponent implements OnInit, OnDestroy {
  public allSub: Subscription[] = [];
  public currentLocale: string;
  public localeTexts: any;

  constructor(
    private localeService: LocaleService,
    private userDataService: UserdataService
  ) { }

  ngOnInit() {
    let locSub = this.userDataService.$localeChange
      .pipe(
        mergeMap(locale => {
          if (this.currentLocale != locale) {
            this.currentLocale = locale;
            return this.localeService.loadRemoteLocale(['loginpanel'], this.userDataService.getEnvironment())
          } else {
            return of([])
          }
        }),
        map(resp => {
          this.onLocaleLoad(resp);
        }),
        catchError(err => {
          let resp = this.localeService.getFbLocaleSections(['loginpanel']);
          this.onLocaleLoad(resp);
          return of([])
        })
      )
      .subscribe()
    this.allSub.push(locSub);
  }

  onLocaleLoad(resp:any) {
    if (resp.length) {
      this.localeTexts = resp[0];
    }
  }

  ngOnDestroy() {
    for (let sub of this.allSub) {
      sub.unsubscribe();
    }
  }
}
