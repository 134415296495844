import { Injectable } from '@angular/core';
import { SsoApiCalls } from './sso-api-call.service';
import { Endpoints } from '../_config/endpoints';
import { switchMap } from 'rxjs/operators';
import { interval, Subscription, Subject, of } from 'rxjs';
import { UserdataService } from './userdata.service';
import { VIEW_COMPONENTS, ERRORS } from '../_components/aa-sso/sso-helper';
import { Observable } from 'rxjs';

@Injectable()
export class UhfSsoService {


    constructor(
        private ssoApiCalls: SsoApiCalls,
        private userDataService: UserdataService
    ) { }


    public environemt: any;

    private apiKey: string;
    private clientId: string;
    private accessToken: string;
    private userId: string;
    private refreshToken: string;
    private accessTokenOld: string;
    private userIdOld: string;
    private refreshTokenOld: string;
    private retriveLoginState: Subscription;
    private tempUserEmail: string;
    private weChatOpenId: string;
    private weChatState: string;
    private isPwa: boolean = false;

    public ssoTriggerView = new Subject<string>();
    private cookieName: string = 'aa_tokens';

    openSignUpOrLogin=(whichOne:string)=>this.ssoTriggerView.next(whichOne);



    setSSOEnvironment(env: string) {
        if (env == 'prod') {
            this.environemt = Endpoints.ssoApisProd;
            this.ssoApiCalls.urls = Endpoints.ssoApisProd["ssoType"];
        } else {
            this.environemt = Endpoints.ssoApisStg;
            this.ssoApiCalls.urls = Endpoints.ssoApisStg["ssoType"];
        }
    }

    getLocale = () => {
        let locale = this.userDataService.getCookie('uhfLocale') ? this.userDataService.getCookie('uhfLocale') : 'en-gb';
        let ssoLocale = (locale.split('-').length == 2) ? locale.split('-')[0] + "-" + locale.split('-')[1].toUpperCase() : 'en-GB';
        return ssoLocale;
    }


    signUp = (username: string, password: string, gender: string, firstName: string, lastName: string) => {
        return this.ssoApiCalls.whichSsoToCall('byOrigin'
            , {}
            , {}
            , "")
            .pipe(
                switchMap(
                    byOriginResponse => {
                        // console.log("byoriginResponse", byOriginResponse);
                        this.apiKey = byOriginResponse["apiKey"];
                        this.clientId = byOriginResponse["id"];

                        var payload = {
                            "username": username,
                            "password": password,
                            "gender": gender,
                            "firstName": firstName,
                            "lastName": lastName,
                            "clientId": this.clientId,
                            "sendEmail": true,
                            "notificationPreference": true,
                            "cultureCode": this.getLocale(),
                            "emailLanguageCode": this.getLocale(),
                            "gaClientId": this.userDataService.getCookie("__airasiaga")
                        }

                        return this.ssoApiCalls.whichSsoToCall('signUp'
                            , payload
                            , { "x-api-key": this.apiKey, "x-aa-client-id": this.clientId }
                            , "")
                    }
                )

            )
        // .subscribe(
        //     userDetails => {
        //         console.log("User signed up susscessful", userDetails);
        //     },
        //     error => {
        //         console.log("Error in SigningUp User", error);
        //         this.notifyLogInStatus(false);
        //         this.clearInterval();
        //     }
        // )
    }



    loginByCredentials = (username: string, password: string) => {

        return this.ssoApiCalls.whichSsoToCall('byOrigin'
            , {}
            , {}
            , "")
            .pipe(
                switchMap(
                    byOriginResponse => {
                        this.apiKey = byOriginResponse["apiKey"];
                        this.clientId = byOriginResponse["id"];

                        //AUTH LOGIN
                        return this.ssoApiCalls.whichSsoToCall('logIn'
                            , { "username": username, "password": password }
                            , { "x-api-key": this.apiKey }
                            , "?clientId=" + this.clientId)
                    }
                ),
                switchMap(
                    logInResponse => {
                        this.userId = logInResponse["userId"];
                        this.accessToken = logInResponse["accessToken"];
                        this.refreshToken = logInResponse["refreshToken"];


                        // SSO LOGIN : BACKWARD COMPATIBILITY
                        return this.ssoApiCalls.whichSsoToCall('backwardLogin'
                            , { "username": username, "password": password }
                            , { "x-api-key": this.apiKey }
                            , "?clientId=" + this.clientId)


                        // let aa_tokens: any = JSON.stringify({ "userId": this.userId, "refreshToken": this.refreshToken, "accessToken": this.accessToken });
                        // this.setLocalAndCookie(this.cookieName, aa_tokens);
                        // return this.ssoApiCalls.whichSsoToCall('userDetails'
                        //     , {}
                        //     , { "x-api-key": this.apiKey, "x-aa-client-Id": this.clientId, "Authorization": this.accessToken }
                        //     , ""
                        //     , this.userId);
                    }
                ),
                switchMap(// SSO LOGIN : BACKWARD COMPATIBILITY
                    logInResponse => {
                        this.userIdOld = logInResponse["userId"];
                        this.accessTokenOld = logInResponse["accessToken"];
                        this.refreshTokenOld = logInResponse["refreshToken"];

                        // console.log("log in older");

                        let aa_tokens: any = JSON.stringify({ "userId": this.userId, "refreshToken": this.refreshToken, "accessToken": this.accessToken });
                        this.setLocalAndCookie(this.cookieName, aa_tokens);
                        return this.ssoApiCalls.whichSsoToCall('userDetails'
                            , {}
                            , { "x-api-key": this.apiKey, "x-aa-client-Id": this.clientId, "Authorization": this.accessToken }
                            , ""
                            , this.userId);
                    }
                )

            )
        // .subscribe(
        //     userDetails => {
        //         console.log("User loggged in susscessful", userDetails);
        //         if (this.retriveLoginState)
        //             this.clearInterval();
        //         this.startInterval();
        //         this.notifyLogInStatus(true, userDetails);
        //     },
        //     error => {
        //         console.log("Error in Logging in User", error);
        //         this.notifyLogInStatus(false);
        //         this.clearInterval();
        //     }
        // )

    }

    recoverPassword = (email: string) => {

        return this.ssoApiCalls.whichSsoToCall('byOrigin'
            , {}
            , {}
            , "")
            .pipe(
                switchMap(
                    byOriginResponse => {
                        this.apiKey = byOriginResponse["apiKey"];
                        this.clientId = byOriginResponse["id"];
                        return this.ssoApiCalls.whichSsoToCall('forgotPassword'
                            , { "email": email }
                            , { "x-api-key": this.apiKey, "x-aa-client-id": this.clientId }
                            , "")
                    }
                )
            )
        // .subscribe(
        //     recoverPasswordResponse => {
        //         console.log("Email send for password reset");
        //     },
        //     error => {
        //         console.log("Error in Sending Recover password", error);
        //     }
        // )

    }


    // Check, if still in session 
    public checkSSOLoginState() {

        return new Promise((resolve, reject) => {
            let userDetailsInPromise: any;

            let aa_tokens_cookie = this.getUhfCookie(this.cookieName) ? JSON.parse(decodeURIComponent(this.getUhfCookie(this.cookieName))) : undefined;
            let refreshToken, userId;
            if (aa_tokens_cookie) {
                refreshToken = aa_tokens_cookie["refreshToken"];
                userId = aa_tokens_cookie["userId"];
            }

            if (aa_tokens_cookie && refreshToken && userId) {
                this.ssoApiCalls.whichSsoToCall('byOrigin'
                    , {}
                    , {}
                    , "")
                    .pipe(
                        switchMap(
                            byOriginResponse => {
                                this.apiKey = byOriginResponse["apiKey"];
                                this.clientId = byOriginResponse["id"];

                                // console.log('API KEY in REFRESH TOKEN: ', this.apiKey)



                                return this.ssoApiCalls.whichSsoToCall('refresh'
                                    , { "refreshToken": refreshToken }
                                    , { "x-api-key": this.apiKey }
                                    , "?clientId=" + this.clientId)
                            }
                        ),
                        switchMap(
                            refreshResponse => {

                                this.userId = userId;
                                this.accessToken = refreshResponse["accessToken"];

                                aa_tokens_cookie['accessToken'] = refreshResponse["accessToken"];
                                this.setLocalAndCookie(this.cookieName, JSON.stringify(aa_tokens_cookie))

                                return this.ssoApiCalls.whichSsoToCall('userDetails'
                                    , {}
                                    , { "x-api-key": this.apiKey, "x-aa-client-Id": this.clientId, "Authorization": this.accessToken }
                                    , ""
                                    , this.userId);
                            }
                        ),
                        switchMap(
                            userDetails => {

                                // this.notifyLogInStatus(true, userDetails);
                                userDetailsInPromise = userDetails;

                                return this.ssoApiCalls.whichSsoToCall('byOrigin'
                                    , {}
                                    , {}
                                    , "")
                            }
                        ),
                        switchMap(
                            byOrigin => {//for backwardd compatibility

                                return this.ssoApiCalls.whichSsoToCall('backwardRefresh'
                                    , {}
                                    , { "x-api-key": this.apiKey }
                                    , "?clientId=" + this.clientId)

                            }
                        )

                    ).subscribe(
                        backwardRefresh => {
                            this.accessTokenOld = backwardRefresh["accessToken"];
                            this.refreshTokenOld = backwardRefresh["refreshToken"];
                            this.userIdOld = backwardRefresh["userId"];
                            resolve(userDetailsInPromise);
                            // if (this.retriveLoginState)
                            //     this.clearInterval();

                            // this.startInterval();

                        },
                        error => {
                            // console.log("User refresh session in the first has error", error);
                            // this.clearInterval();

                            // let errCode = error['error'] && error['error']['code'] ? error['error']['code'] : null;
                            // this.notifyLogInStatus(false, null, errCode);
                            // this.clearLocalAndCookie(this.cookieName);
                            reject(error);

                        }
                    )
            }
            else {
                this.ssoApiCalls.whichSsoToCall('byOrigin'
                    , {}
                    , {}
                    , "")
                    .pipe
                    (
                    switchMap(
                        byOriginResponse => {
                            // console.log("response is", byOriginResponse);
                            this.apiKey = byOriginResponse['apiKey'];
                            this.clientId = byOriginResponse["id"];
                            // console.log("clientid is", this.clientId);
                            return this.ssoApiCalls.whichSsoToCall('backwardRefresh'
                                , {}
                                , { "x-api-key": this.apiKey }
                                , "?clientId=" + this.clientId)
                        }
                    ),
                    switchMap(
                        backwardRefresh => {
                            this.accessToken = this.accessTokenOld = backwardRefresh["accessToken"];
                            this.refreshToken = this.refreshTokenOld = backwardRefresh["refreshToken"];
                            this.userId = this.userIdOld = backwardRefresh["userId"];

                            return this.ssoApiCalls.whichSsoToCall('userDetails'
                                , {}
                                , { "x-api-key": this.apiKey, "x-aa-client-Id": this.clientId, "Authorization": this.accessToken }
                                , ""
                                , this.userId);
                        }
                    )

                    )
                    .subscribe(
                        userDetails => {
                            // console.log("getting user details success in else");
                            // this.notifyLogInStatus(true, userDetails);
                            // let aa_tokens: any = JSON.stringify({ "userId": this.userId, "refreshToken": this.refreshToken, "accessToken": this.accessToken });
                            // this.setLocalAndCookie(this.cookieName, aa_tokens);

                            // if (this.retriveLoginState)
                            //     this.clearInterval();

                            // this.startInterval();
                            resolve(userDetails);
                        },
                        error => {
                            // console.log("ok i am in error in else");
                            // this.clearInterval();
                            // this.notifyLogInStatus(false);
                            // localStorage.removeItem(this.cookieName);
                            reject(error);
                        })

            }

        })
    }


    refreshUserSession = (tokens?: any) => {

        if ((tokens) || (this.isPwa == true)) {
            if (this.isPwa == false) {
                this.setLocalAndCookie(this.cookieName, tokens);
            }
            let aa_tokens_cookie = this.getUhfCookie(this.cookieName) ? JSON.parse(decodeURIComponent(this.getUhfCookie(this.cookieName))) : undefined;
            let refreshToken, userId;
            if (aa_tokens_cookie) {
                refreshToken = aa_tokens_cookie["refreshToken"];
                userId = aa_tokens_cookie["userId"];
            }
            this.isPwa = true;

            if (aa_tokens_cookie && refreshToken && userId) {
                this.ssoApiCalls.whichSsoToCall('byOrigin'
                    , {}
                    , {}
                    , "")
                    .pipe(
                        switchMap(
                            byOriginResponse => {
                                this.apiKey = byOriginResponse["apiKey"];
                                this.clientId = byOriginResponse["id"];

                                // console.log('API KEY in REFRESH TOKEN: ', this.apiKey)



                                return this.ssoApiCalls.whichSsoToCall('refresh'
                                    , { "refreshToken": refreshToken }
                                    , { "x-api-key": this.apiKey }
                                    , "?clientId=" + this.clientId)
                            }
                        ),
                        switchMap(
                            refreshResponse => {

                                this.userId = userId;
                                this.accessToken = refreshResponse["accessToken"];

                                aa_tokens_cookie['accessToken'] = refreshResponse["accessToken"];
                                this.setLocalAndCookie(this.cookieName, JSON.stringify(aa_tokens_cookie))

                                return this.ssoApiCalls.whichSsoToCall('userDetails'
                                    , {}
                                    , { "x-api-key": this.apiKey, "x-aa-client-Id": this.clientId, "Authorization": this.accessToken }
                                    , ""
                                    , this.userId);
                            }
                        )
                    ).subscribe(
                        userDetails => {
                            this.notifyLogInStatus(true, userDetails);

                            if (this.retriveLoginState)
                                this.clearInterval();

                            this.startInterval();

                        },
                        error => {
                            // console.log("User refresh session for pwa has error", error);
                            this.clearInterval();

                            let errCode = error['error'] && error['error']['code'] ? error['error']['code'] : null;
                            this.notifyLogInStatus(false, null, errCode);

                            this.clearLocalAndCookie(this.cookieName);
                        }
                    )
            }
            else {
                // console.log("the cookie was not present in pwa refresh")
                this.clearInterval();
                this.notifyLogInStatus(false);
                localStorage.removeItem(this.cookieName)
            }

        }
        else {

            let aa_tokens_cookie = this.getUhfCookie(this.cookieName) ? JSON.parse(decodeURIComponent(this.getUhfCookie(this.cookieName))) : undefined;
            let refreshToken, userId;
            if (aa_tokens_cookie) {
                refreshToken = aa_tokens_cookie["refreshToken"];
                userId = aa_tokens_cookie["userId"];
            }

            if (aa_tokens_cookie && refreshToken && userId) {
                this.ssoApiCalls.whichSsoToCall('byOrigin'
                    , {}
                    , {}
                    , "")
                    .pipe(
                        switchMap(
                            byOriginResponse => {
                                this.apiKey = byOriginResponse["apiKey"];
                                this.clientId = byOriginResponse["id"];

                                // console.log('API KEY in REFRESH TOKEN: ', this.apiKey)



                                return this.ssoApiCalls.whichSsoToCall('refresh'
                                    , { "refreshToken": refreshToken }
                                    , { "x-api-key": this.apiKey }
                                    , "?clientId=" + this.clientId)
                            }
                        ),
                        switchMap(
                            refreshResponse => {

                                this.userId = userId;
                                this.accessToken = refreshResponse["accessToken"];

                                aa_tokens_cookie['accessToken'] = refreshResponse["accessToken"];
                                this.setLocalAndCookie(this.cookieName, JSON.stringify(aa_tokens_cookie))

                                // return this.ssoApiCalls.whichSsoToCall('userDetails'
                                //     , {}
                                //     , { "x-api-key": this.apiKey, "x-aa-client-Id": this.clientId, "Authorization": this.accessToken }
                                //     , ""
                                //     , this.userId);
                                return this.ssoApiCalls.whichSsoToCall('backwardRefresh'
                                    , {}
                                    , { "x-api-key": this.apiKey }
                                    , "?clientId=" + this.clientId)
                            }
                        ),
                        // switchMap(
                        //     userDetails => {

                        //         this.notifyLogInStatus(true, userDetails);

                        //         return this.ssoApiCalls.whichSsoToCall('byOrigin'
                        //             , {}
                        //             , {}
                        //             , "")
                        //     }
                        // ),
                        switchMap(
                            backwardRefresh => {//for backwardd compatibility

                                this.accessTokenOld = backwardRefresh["accessToken"];
                                this.refreshTokenOld = backwardRefresh["refreshToken"];
                                this.userIdOld = backwardRefresh["userId"];

                                // return this.ssoApiCalls.whichSsoToCall('backwardRefresh'
                                //     , {}
                                //     , { "x-api-key": this.apiKey }
                                //     , "?clientId=" + this.clientId)
                                return this.ssoApiCalls.whichSsoToCall('userDetails'
                                    , {}
                                    , { "x-api-key": this.apiKey, "x-aa-client-Id": this.clientId, "Authorization": this.accessToken }
                                    , ""
                                    , this.userId);

                            }
                        )

                    ).subscribe(
                        userDetails => {
                            // this.accessTokenOld = backwardRefresh["accessToken"];
                            // this.refreshTokenOld = backwardRefresh["refreshToken"];
                            // this.userIdOld = backwardRefresh["userId"];

                            this.notifyLogInStatus(true, userDetails);

                            if (this.retriveLoginState)
                                this.clearInterval();

                            this.startInterval();

                        },
                        error => {
                            // console.log("User refresh session in the first has error", error);
                            this.clearInterval();

                            let errCode = error['error'] && error['error']['code'] ? error['error']['code'] : null;
                            this.notifyLogInStatus(false, null, errCode);

                            // this.clearLocalAndCookie(this.cookieName);
                        }
                    )
            }
            else {
                this.ssoApiCalls.whichSsoToCall('byOrigin'
                    , {}
                    , {}
                    , "")
                    .pipe
                    (
                    switchMap(
                        byOriginResponse => {
                            // console.log("response is", byOriginResponse);
                            this.apiKey = byOriginResponse['apiKey'];
                            this.clientId = byOriginResponse["id"];
                            // console.log("clientid is", this.clientId);
                            return this.ssoApiCalls.whichSsoToCall('backwardRefresh'
                                , {}
                                , { "x-api-key": this.apiKey }
                                , "?clientId=" + this.clientId)
                        }
                    ),
                    switchMap(
                        backwardRefresh => {
                            this.accessToken = this.accessTokenOld = backwardRefresh["accessToken"];
                            this.refreshToken = this.refreshTokenOld = backwardRefresh["refreshToken"];
                            this.userId = this.userIdOld = backwardRefresh["userId"];

                            return this.ssoApiCalls.whichSsoToCall('userDetails'
                                , {}
                                , { "x-api-key": this.apiKey, "x-aa-client-Id": this.clientId, "Authorization": this.accessToken }
                                , ""
                                , this.userId);
                        }
                    )

                    )
                    .subscribe(
                        userDetails => {
                            // console.log("getting user details success in else");
                            this.notifyLogInStatus(true, userDetails);
                            let aa_tokens: any = JSON.stringify({ "userId": this.userId, "refreshToken": this.refreshToken, "accessToken": this.accessToken });
                            this.setLocalAndCookie(this.cookieName, aa_tokens);

                            if (this.retriveLoginState)
                                this.clearInterval();

                            this.startInterval();
                        },
                        error => {
                            // console.log("ok i am in error in else");
                            this.clearInterval();
                            this.notifyLogInStatus(false);
                            localStorage.removeItem(this.cookieName)
                        })

            }
        }

    }


    startInterval = () => {

        this.retriveLoginState = interval(this.environemt['iteratorMilliSeconds']).subscribe(
            x => {
                // console.log("User Session refresh Called again after");
                this.refreshUserSession();
            },
            error => {
                // console.log("User Session Refresher failed")
                this.notifyLogInStatus(false);
            }
        )
    }

    clearInterval = () => {
        if (this.retriveLoginState)
            this.retriveLoginState.unsubscribe();
        this.retriveLoginState = null;
    }





    logOut = (admin: boolean = false) => {

        this.clearInterval();
        // console.log("api-key", this.apiKey);
        // console.log("clientId", this.clientId);
        // console.log("Authorization", this.accessToken);
        // console.log("accesstokenold", this.accessTokenOld);

        this.clearLocalAndCookie(this.cookieName);
        // this.notifyLogInStatus(false);


        if (this.isPwa == false) {
            return this.ssoApiCalls
                .whichSsoToCall('backwardLogout'
                    , {}
                    , { "x-api-key": this.apiKey, "x-aa-client-id": this.clientId, "Authorization": this.accessTokenOld }
                    , "")
        }
        else {
            return this.ssoApiCalls
                .whichSsoToCall('logOut'
                    , { "admin": admin }
                    , { "x-api-key": this.apiKey, "x-aa-client-id": this.clientId, "Authorization": this.accessToken }
                    , "")

        }

        // .pipe(
        //     switchMap(
        //         response => {
        //             console.log("log out new is success");

        //             return this.ssoApiCalls
        //                 .whichSsoToCall('logOut'
        //                     , { "admin": admin }
        //                     , { "x-api-key": this.apiKey, "x-aa-client-id": this.clientId, "Authorization": this.accessToken }
        //                     , "")

        //         }

        //     )
        // )

        // .subscribe(
        //     logoutResponse => {
        //         console.log("Logout new is success")
        //     },
        //     error => {
        //         console.log("Logout new was unsuccessful")
        //     });






    }



    notifyLogInStatus = (status, userDetails = null, error = null) =>
        this.userDataService.updateSsoLoginSubject({ "status": status, "ssoData": userDetails, "ssoError": error });

    loginBySocialNetwork(network) {

        this.ssoApiCalls.whichSsoToCall('byOrigin'
            , {}
            , {}
            , "")
            .subscribe(
                byOriginResponse => {
                    this.apiKey = byOriginResponse["apiKey"];
                    this.clientId = byOriginResponse["id"];
                    this.openWindow(network);
                },
                error => {
                    //console.log("Error in Origin Api in login with Social Network");
                }
            )

    }

    weChatSignupCall = (state: string, openid: string, username: string, firstName: string, lastName: string, gender: string) => {
        return this.ssoApiCalls.whichSsoToCall('byOrigin'
            , {}
            , {}
            , "")
            .pipe(
                switchMap(
                    byOriginResponse => {
                        this.apiKey = byOriginResponse["apiKey"];
                        //console.log("The api call in by origin of we chat signup", this.apiKey);
                        return this.ssoApiCalls.whichSsoToCall('weChatLogin'
                            , {}
                            , { "x-api-key": this.apiKey }
                            , `?state=${state}&openid=${openid}&username=${username}&firstName=${firstName}&lastName=${lastName}&gender=${gender}`);
                    }
                ),
                switchMap(
                    confirmSignUp => {
                        this.userId = confirmSignUp["userId"];
                        this.accessToken = confirmSignUp["accessToken"];
                        this.refreshToken = confirmSignUp["refreshToken"];
                        if (!this.userId && confirmSignUp['errormsg'] === 'emailExists') {
                            this.setTempEmail(username);
                            this.notifyLogInStatus(false, null, ERRORS.USER_ALREADY_EXISTS);
                            return;
                        }
                        // console.log("The api call in sign up of we chat");
                        // console.log("user id", this.userId);
                        // console.log("access token", this.accessToken);
                        // console.log("refresh token", this.refreshToken);
                        // console.log("api key", this.apiKey);

                        return this.ssoApiCalls.whichSsoToCall('weChatSignupVerify'
                            , {}
                            , { "x-api-key": this.apiKey }
                            , `?accessToken=${confirmSignUp["accessToken"]}&refreshToken=${confirmSignUp["refreshToken"]}&userId=${confirmSignUp["userId"]}&type=signup`);
                    }
                )


            )
            .subscribe(
                userDetails => {

                    // console.log("User signed up in wechat");
                    this.userId = userDetails["userId"];
                    this.accessToken = userDetails["accessToken"];
                    this.refreshToken = userDetails["refreshToken"];
                    // console.log("The api call in sign up of we chat");
                    // console.log("user id", this.userId);
                    // console.log("access token", this.accessToken);
                    // console.log("refresh token", this.refreshToken);
                    // console.log("api key", this.apiKey);
                    let aa_tokens: any = JSON.stringify({ "userId": this.userId, "refreshToken": this.refreshToken, "accessToken": this.accessToken });
                    this.setLocalAndCookie(this.cookieName, aa_tokens);
                    this.ssoTriggerView.next(VIEW_COMPONENTS.CLOSE_MODAL);
                    setTimeout(() => {
                        this.refreshUserSession();
                    }, 5000);
                    // return this.ssoApiCalls.whichSsoToCall('userDetails'
                    //     , {}
                    //     , { "x-api-key": this.apiKey, "x-aa-client-Id": this.clientId, "Authorization": this.accessToken }
                    //     , ""
                    //     , this.userId);
                },
                error => {
                    // console.log("Error in SigningUp User wechat", error);
                    // this.refreshUserSession();
                    // console.log("Error in closing wechat model");
                    this.ssoTriggerView.next(VIEW_COMPONENTS.CLOSE_MODAL);
                }
            )
    }

    openWindow = (network) => {
        try {
            let redirectUrl = window.location.href;
            const popupOptions = this.getPopupOptions();
            let popupUrl = this.environemt.ssoType.socialLogin.replace('{network}', network).replace('{redirectUrl}', redirectUrl).replace('{clientId}', this.clientId);

            let popup = window.open(popupUrl, 'popup', popupOptions);

            let responseInterval = setInterval(() => {
                try {
                    let url = popup.location.href;
                    if (url && url.indexOf(redirectUrl) !== -1) {

                        if (network === 'wechat') {
                            this.weChatOpenId = this.parseUrlHash(url, 'openId');
                            this.weChatState = this.parseUrlHash(url, 'state')
                            this.accessToken = this.parseUrlHash(url, 'accessToken')
                            this.refreshToken = this.parseUrlHash(url, 'refreshToken')
                            this.userId = this.parseUrlHash(url, 'userId')

                            if (this.weChatOpenId && this.weChatState) {
                                clearInterval(responseInterval);
                                popup.close();

                                this.ssoTriggerView.next(VIEW_COMPONENTS.WECHAT_USER_DETAILS);
                                // Call wechat login API using openid & state then call refreshUserSession
                                // Observable - WECHAT SIGNUP - Show User Details 
                                // On submit call below API from component.
                                //this.weChatSignupCall( this.weChatState, this.weChatOpenId, 'zxzxzx@gmail.com', 'Gangesh', 'Sharma', 'male')
                            } else if (this.accessToken && this.refreshToken && this.userId) {
                                clearInterval(responseInterval);
                                popup.close();

                                let aa_tokens: any = {};
                                aa_tokens["userId"] = this.userId;
                                aa_tokens["accessToken"] = this.accessToken;
                                aa_tokens["refreshToken"] = this.refreshToken;
                                this.setLocalAndCookie(this.cookieName, JSON.stringify(aa_tokens))

                                // Observable - WECHAT SIGNIN - Close Login Modal
                                this.ssoTriggerView.next(VIEW_COMPONENTS.CLOSE_MODAL);
                                this.refreshUserSession();
                            }
                        } else {

                            this.accessToken = this.parseUrlHash(url, 'accessToken');
                            this.refreshToken = this.parseUrlHash(url, 'refreshToken');
                            this.userId = this.parseUrlHash(url, 'userId');

                            if (this.accessToken != "" && this.refreshToken != "" && this.userId != "") {
                                let aa_tokens: any = {};
                                aa_tokens["userId"] = this.userId;
                                aa_tokens["accessToken"] = this.accessToken;
                                aa_tokens["refreshToken"] = this.refreshToken;
                                this.setLocalAndCookie(this.cookieName, JSON.stringify(aa_tokens))

                                // console.log("Received tokens: ", localStorage.getItem(this.cookieName))
                                clearInterval(responseInterval);
                                popup.close();
                                this.refreshUserSession();
                            }
                        }
                    }
                } catch (error) {
                    // console.log('ERROR LOGGINGGGGGG  ', error)                    
                    // clearInterval(responseInterval);
                }
            }, 0)
        }
        catch (error) {
            // console.log('ERROR LOGGINGGGGGG CATCH BOTTOM', error)
        }
    }

    parseUrlHash = (url, name) => {
        name = name.replace(/[\[]/, '\[').replace(/[\]]/, '\]');
        let regexString = '[\?&#]' + name + '=([^&#]*)';
        let regex = new RegExp(regexString);
        let results = regex.exec(url);

        if (results === null)
            return '';

        return results[1];
    }

    getPopupOptions() {
        const windowOptions = 'scrollbars=1,resizable=1,toolbar=0,location=0,dialog=1',
            width = 750,
            height = 520,
            winHeight = screen.height,
            winWidth = screen.width;
        let left,
            top = 0;

        left = Math.round((winWidth / 2) - (width / 2));
        if (winHeight > height) {
            top = Math.round((winHeight / 2) - (height / 2));
        }

        return `${windowOptions},width=${width},height=${height},left=${left},top=${top}`;
    }



    updateUserDetails = (body: any) => {
        // console.log('userid', this.userId)
        return this.ssoApiCalls.whichSsoToCall('byOrigin'
            , {}
            , {}
            , "")
            .pipe(
                switchMap(
                    byOriginResponse => {
                        this.apiKey = byOriginResponse["apiKey"];
                        this.clientId = byOriginResponse["id"];
                        return this.ssoApiCalls.whichSsoToCall(
                            'updateUserDetails'
                            , { "gender": body["gender"], "firstName": body["firstName"], "lastName": body["lastName"], "clientId": this.clientId }
                            , { "x-api-key": this.apiKey, "x-aa-client-id": this.clientId, "Authorization": this.accessToken }
                            , ""
                            , this.userId)
                    }
                )
            )
        // .subscribe(
        //     updateUserDetails => {
        //         console.log("User Details Updated");
        //         this.refreshUserSession();

        //     },
        //     error => {
        //         console.log("Error in user Details supdating", error);
        //     }
        // )
    }
    

    shouldRetriveLoginState() {
        return this.retriveLoginState;
    }

    setSignUpUserId(userId: string) {
        this.userId = userId;
    }

    getClientId() {
        return this.clientId;
    }

    getApiKey() {
        return this.apiKey;
    }

    setTempEmail(email: string) {
        this.tempUserEmail = email;
    }

    getTempEmail() {
        return this.tempUserEmail;
    }

    userActivateKeys = (email: string) => {
        return this.ssoApiCalls.whichSsoToCall('byOrigin'
            , {}
            , {}
            , "")
            .pipe(
                switchMap(
                    byOriginResponse => {
                        this.apiKey = byOriginResponse["apiKey"];
                        this.clientId = byOriginResponse["id"];
                        return this.ssoApiCalls.whichSsoToCall(
                            'activateKeys'
                            , { "email": email, "emailLanguageCode": this.getLocale() }
                            , { "x-api-key": this.apiKey, "x-aa-client-id": this.clientId }
                            , ""
                            , "")
                    }
                )
            )
            .subscribe(
                sendActivationEmail => {
                    // console.log("Sent Activation Email", sendActivationEmail);
                },
                error => {
                    // console.log("Error in send Activation Email", error);
                }
            )
    }

    getUhfCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return;
    }

    getBaseDomain = (ar = (window.location.host.split(":")[0]).split('.')) => {
        return ar.length >= 2 ? "." + ar[ar.length - 2] + "." + ar[ar.length - 1] : null;
    }


    setLocalAndCookie = (name, value, days: number = 180) => {

        localStorage.setItem(name, value);

        let valueCopy = JSON.stringify(JSON.parse(value));
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        if (this.getBaseDomain().indexOf('airasia') > -1) {
            document.cookie = name + "=" + (encodeURIComponent(valueCopy) || "") + expires + ";domain=" + this.getBaseDomain() + ";path=/";
        } else {
            document.cookie = name + "=" + (encodeURIComponent(valueCopy) || "") + expires + " ;path=/";
        }
    }

    clearLocalAndCookie = (name) => {
        localStorage.removeItem(name);
        if (this.getBaseDomain().indexOf('airasia') > -1) {
            document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${this.getBaseDomain()}`;
        } else {
            document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        }
    }

    getWeChatOpenId() {
        return this.weChatOpenId;
    }

    getWeChatState() {
        return this.weChatState;
    }
}
