import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { throwError as observableThrowError, Observable } from 'rxjs';

@Injectable()
export class BaseHttpService {


    constructor(
        private http: HttpClient,
    ) { }


    public getHttpHeader(headers: any): HttpHeaders {
        let headersReturned = new HttpHeaders(headers)
        return headersReturned
    }


    get = (url: string, headers: any = {}) => {
        let headersReturned: HttpHeaders = this.getHttpHeader(headers);

        return this.http.get(url, { headers: headersReturned })
            .pipe(
                map((response) => {
                    return response;
                }),
                catchError((err) => {
                    return observableThrowError(err);
                })
            )

    }

    get1 = (url: string, headers: any = {}) => {
        let headersReturned: HttpHeaders = this.getHttpHeader(headers);

        return this.http.get(url, { headers: headersReturned, withCredentials: true })
            .pipe(
                map((response) => {
                    return response;
                }),
                catchError((err) => {
                    return observableThrowError(err);
                })
            )

    }

    post = (url: string, body: any = {}, headers: any = {}) => {
        let headersReturned: HttpHeaders = this.getHttpHeader(headers);

        return this.http.post(url, body, { headers: headersReturned })
            .pipe(
                map((response) => {
                    return response;
                }),
                catchError((err) => {
                    return observableThrowError(err);
                })
            )

    }

    post1 = (url: string, body: any = {}, headers: any = {}) => {
        let headersReturned: HttpHeaders = this.getHttpHeader(headers);
        return this.http.post(url, body, { headers: headersReturned, withCredentials: true })
            .pipe(
                map((response) => {
                    return response;
                }),
                catchError((err) => {
                    return observableThrowError(err);
                })
            )

    }

    patch = (url: string, body: any = {}, headers: any = {}) => {
        let headersReturned: HttpHeaders = this.getHttpHeader(headers);

        return this.http.patch(url, body, { headers: headersReturned })
            .pipe(
                map((response) => {
                    return response;
                }),
                catchError((err) => {
                    return observableThrowError(err);
                })
            )

    }


}
