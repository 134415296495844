import { Component, OnInit, Input, ViewChild, Output, EventEmitter, ElementRef, forwardRef } from '@angular/core';
import { FormGroup, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'aa-input',
  template: `<div class='input-field' [ngClass]="{'active': isFocus, 'error':error}">
  <span *ngIf='tooltipText && isFocus' class='tooltip'>{{tooltipText}}</span>
  <input #inputCtrl 
    [id]="id" 
    [type]="type !== 'password' ? type : isPasswordVisible ? 'text' : type" 
    [autocomplete]="autocomplete"
    [(ngModel)]="value"
    (focus)='setInputFocus(true)'
    (blur)='setInputFocus(false)'
    [minlength]="minLength"
    [maxlength]="maxLength"
    [ngClass]="{'password-input' : type === 'password'}"
  />
  <label [attr.for]="id"  [ngClass]="{'active': valueLength() || isFocus}">
    {{labelText}}
  </label>
  <mat-icon 
    *ngIf="type === 'password'"
    class='password-visibility'
    (click)='togglePasswordVisibility()'
    [attr.aria-label]="isPasswordVisible ? 'Hide password' : 'Show password'" 
    [title]="isPasswordVisible ? 'Hide password' : 'Show password'"
  >
    {{isPasswordVisible ? 'visibility' : 'visibility_off'}}
  </mat-icon>
</div>`,
  styles: [`.input-field{border-radius:4px;border:1px solid #bfc5cf;box-sizing:border-box;position:relative}.input-field.active{border:1px solid #359ef2}.input-field.active label.active{color:#359ef2}.input-field.error{border:1px solid #e10}.input-field.error label.active{color:#e10}input{background-color:transparent;border:none;border-radius:0;outline:0;height:50px;width:100%;font-size:1rem;box-shadow:none;box-sizing:border-box;transition:.3s;margin:auto;padding:20px 10px 0}input.password-input{padding:20px 60px 0 10px}label{pointer-events:none;width:92%;padding:0 0 0 10px;box-sizing:border-box;top:25px;font-size:1rem;color:#84888f;position:absolute;left:0;cursor:text;transition:.2s ease-out}label:after{display:block;content:"";position:absolute;top:60px;opacity:0;transition:opacity .2s ease-out,color .2s ease-out}label.active{font-size:.8rem;-webkit-transform:translateY(-140%);transform:translateY(-140%)}.password-visibility{position:absolute;cursor:pointer;top:0;right:0;height:50px;width:50px;display:flex;align-items:center;justify-content:center;color:#72777e}.password-visibility:after{content:'';height:26px;width:1px;border-left:1px solid #bfc5cf;position:absolute;left:0}.tooltip{position:absolute;width:100%;padding:9px 11px;bottom:126%;left:0;background-color:#fff;font-size:15px;box-sizing:inherit;color:#1d1d1d;border-radius:6px;-webkit-filter:drop-shadow(rgba(0, 0, 0, .3) 0 2px 10px);filter:drop-shadow(rgba(0, 0, 0, .3) 0 2px 10px)}.tooltip::after{content:"";position:absolute;top:100%;left:5px;border:10px solid;border-color:#fff transparent transparent}`],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => AaInputComponent),
    multi: true
  }]
})
export class AaInputComponent implements OnInit, ControlValueAccessor {

  @Input() id: string;
  @Input() labelText: string;
  @Input() type: string;
  @Input() formControlName: string;
  @Input() form: FormGroup;
  @Input() autocomplete: string;
  @Input() focusOnInit: boolean;
  @Input() maxLength: number;
  @Input() minLength: number;
  @Input() error: boolean = false;
  @Input() tooltipText: string;
  @ViewChild('inputCtrl') inputCtrl: ElementRef;

  private _value: any = '';
  public isFocus: boolean;
  public isPasswordVisible: boolean = false;


  onChange: any = () => { };
  onTouched: any = () => { };

  get value() {
    return this._value;
  }

  set value(val) {
    this._value = val;
    this.onChange(val);
    this.onTouched();
  }

  constructor() { }

  ngOnInit() {
    this.type = (this.type) ? this.type : 'text';
    if (this.focusOnInit) {
      this.inputCtrl.nativeElement.focus();
    }
  }

  togglePasswordVisibility():void {
    this.isPasswordVisible = !this.isPasswordVisible
  }

  setInputFocus(val: boolean):void {
    this.isFocus = val;
  }

  valueLength = () => this.inputCtrl.nativeElement.value.length !== 0;

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) { 
    this.onTouched = fn;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }
  }
}
