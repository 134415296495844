import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AaFooterComponent } from '../../_components/aa-footer/aa-footer.component';
import { LocaleService } from '../../_services/locale.service';
import { ComponentSettingService } from '../../_services/component-setting.service';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    AaFooterComponent
  ],
  providers: [
    LocaleService,
    ComponentSettingService
  ],
  exports: [
    AaFooterComponent
  ]
})
export class FooterModule { }
