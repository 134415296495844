import { Component, OnInit, ContentChildren, QueryList, ViewChildren, HostListener, ElementRef, ViewChild } from '@angular/core';
import { TabComponent as Tab } from '../aa-tab/aa-tab.component'
import { ListKeyManager, FocusMonitor } from '@angular/cdk/a11y'
import { fromEvent } from 'rxjs';

@Component({
  selector: 'aa-tabs',
  template: `<div>
  <div class="tab-header">
    <ul #tabhead role="tablist" aria-label="Press left and right arrow keys to navigate between the tabs">
      <li [attr.tabindex]="tab.active ? 0 : -1" #tabtitles [attr.aria-selected]="tab.active"
        *ngFor='let tab of tabs;let i=index;' id="{{'loginOrSignUp'+i }}" (click)="selectTab(tab)"
        [class.selectedTab]="tab.active" role="tab" [class.focusedTab]="tab._isFocused">{{tab.title}}</li>
    </ul>
  </div>
  <section>
    <ng-content></ng-content>
  </section>
</div>`,
  styles: [`.tab-header ul{margin:0;padding:0;list-style-type:none;display:flex;flex-wrap:nowrap;justify-content:flex-start}.tab-header ul li{padding:16px 0;width:100%;text-align:center;cursor:pointer;font-size:1.1rem;background:#f7f9fc;border:1px solid #bfc5cf}.tab-header ul li.selectedTab{border:none;border-top:5px solid #067e41;background:#fff}.tab-header ul li.focusedTab{background:#87ceeb;opacity:.7}`]
})
export class TabsComponent implements OnInit {
  @ContentChildren(Tab) tabs: QueryList<Tab>;
  keyManager: any;

  @ViewChildren('tabtitles') tabtitles: QueryList<any> = null;
  @ViewChild('tabhead') tabhead: ElementRef;

  constructor(private focusMonitor: FocusMonitor, private elementRef: ElementRef) { }
  ngOnInit() {
  }

  ngAfterContentInit() {
    let activeTabs = this.tabs.filter((tab) => tab.active);

    if (activeTabs.length === 0) {
      this.selectTab(this.tabs.first);
    }
  }

  ngAfterViewInit() {
    this.keyManager = new ListKeyManager(this.tabtitles)
    this.keyManager.withHorizontalOrientation('ltr')
    this.keyManager.withWrap()
    this.keyManager.change.asObservable().subscribe((ind) => {
      this.setTabFocus(this.tabs.toArray()[ind])
    })

    this.keyManager.tabOut.asObservable().subscribe(
      () => {
        this.tabs.toArray().forEach(tab => tab._isFocused = false);
      }
    )
    // this.keyManager.change

    this.focusMonitor.monitor(this.tabhead.nativeElement, true).subscribe(
      (value) => {
        if (value !== 'keyboard') {
          this.tabs.toArray().forEach(tab => tab._isFocused = false);
        }
      }
    )

    fromEvent(this.tabhead.nativeElement, 'keyup').subscribe(this._handleEvent.bind(this))
  }

  _handleEvent(event: any) {
    if (event.code == "Enter" || event.code == "Space") {

      this.selectTab(this.tabs['_results'].filter(
        ({ title }) => title == this.keyManager.activeItem.nativeElement.textContent
      )[0])
      this.focusMonitor.focusVia(this.keyManager.activeItem.nativeElement, "keyboard")
    }
    if (event.code !== 'Tab') {
      this.keyManager.onKeydown(event)
      this.focusMonitor.focusVia(this.keyManager.activeItem.nativeElement, "keyboard")
    }
    else { 
      this.keyManager.onKeydown(event)
      this.keyManager.setNextItemActive()
    }
  }

  setTabFocus(tab: Tab) {
    this.tabs.toArray().forEach(tab => tab._isFocused = false);
    tab._isFocused = true;
  }

  selectTab(tab: Tab) {
    this.tabs.toArray().forEach(tab => {
      tab.active = false
    }

    );
    tab.active = true;
  }

}
