import { Component, OnInit, Input, EventEmitter, OnChanges, Output, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { RadioButtonItem } from '../../../shared/aa-radio-button/aa-radio-button.model';
import { GENDER_OPTIONS, VIEW_COMPONENTS, ERRORS, isEmail, isName } from '../sso-helper';
import { Subscription } from 'rxjs';
import { UhfSsoService } from '../../../_services/uhfssoservice.service';
import { UserdataService } from '../../../_services/userdata.service';

const DEFAULT_GENDER = GENDER_OPTIONS.MALE;

@Component({
  selector: 'sso-user-details',
  template: `<div #userDetailsSection tabindex='-1' class='sso-user-details'>
  <div class='user-details'>
    <h2>{{localeTexts?.signupTab?.noNameHeader}}</h2>
    <p>{{localeTexts?.signupTab?.noNameSubHeader}}</p>
    <form 
      [formGroup]="form" 
      (ngSubmit)='onSubmit()'
    >
    <div *ngIf='errorMessage' class='sso-error-message' role="alert">
      <mat-icon aria-hidden='true'>error</mat-icon>
      <div>{{errorMessage}}</div>
    </div>

      <div class='sso-row sso-gender-group'>
        <aa-radio-button
          [groupName]="'sso-user-gender'"
          [id]="'sso-user-gender-input'"
          [dataList]="genderOptions"
          formControlName="gender"
          [form]="form"
        >
        ></aa-radio-button>
      </div>
      <div class='sso-user-details-inputs'>
        <div class='sso-row sso-given-name'>
          <aa-input
            [id]="'sso-user-given-name-input'"
            [labelText]="localeTexts?.signupTab?.givenName"
            formControlName="firstName"
            [form]="form"
            [autocomplete]="'given-name'"
            [error]="errorFirstName"
            [tooltipText]="localeTexts?.main?.tooltipText"
          >
          </aa-input>
        </div>
        <div class='sso-row sso-family-name'>
          <aa-input
            [id]="'sso-user-given-name-input'"
            [labelText]="localeTexts?.signupTab?.familyName"
            formControlName="lastName"
            [form]="form"
            [autocomplete]="'family-name'"
            [error]="errorLastName"
            [tooltipText]="localeTexts?.main?.tooltipText"
          >
          </aa-input>
        </div>

        <div *ngIf='isFromWeChat' class='sso-row sso-email'>
          <aa-input
            [id]="'sso-user-email-input'"
            [labelText]="localeTexts?.main?.email"
            formControlName="email"
            [form]="form"
            [type]="'email'"
            [autocomplete]="'email'"
            [error]="errorEmail"
            [maxLength]='64'
          >
          </aa-input>
        </div>

        <div class='sso-row sso-user-button'>
          <button type='submit' #submitButton class='sso-button' [disabled]='isLoading'>
            <mat-spinner *ngIf='isLoading' class='white-spinner' [diameter]="22"></mat-spinner>
            <ng-container *ngIf='!isLoading'>
                {{localeTexts?.signupTab?.save}}
            </ng-container>
          </button>
        </div>

      </div>
    </form>
  </div>
</div>
`,
  styles: [`button{border:none;background:0 0;cursor:pointer}.sso-social-items{display:flex;flex-direction:row;justify-content:space-around}.sso-social-items .sso-social-item{display:flex;flex-direction:column;align-items:center;cursor:pointer}.sso-social-items .sso-social-item .sso-social-icon{width:50px;height:50px;border-radius:50%}.sso-row{display:flex;flex-direction:row}.sso-column{display:flex;flex:1;flex-direction:column}.sso-button{width:100%;font-size:1.18rem;background:#e10000;border-radius:4px;border:none;padding:15px;color:#fff;cursor:pointer;display:flex;justify-content:center}.sso-button:disabled{cursor:progress}ul{list-style-type:none;padding:0}.sso-error-message{box-sizing:border-box;background-color:#ffebee;padding:12px;margin:15px 0;border-radius:5px;color:#e10000;width:100%;display:flex;font-size:.9rem;align-items:flex-start}.sso-error-message div{margin-left:10px;display:block;align-self:center}.sso-error-message a{text-decoration:underline}.sso-user-details{padding:15px 25px}.sso-gender-group{padding-bottom:10px}.user-details{padding:20px 0}h2{font-size:1.25rem;font-weight:600;margin:0}p{margin:5px 0 20px;font-size:.95rem}aa-input{width:100%}.sso-user-details-inputs .sso-given-name{padding:10px 0}.sso-user-details-inputs .sso-email{padding-top:10px}.sso-user-button{margin-top:10px}`]
})

export class SsoUserDetailsComponent implements OnInit, OnChanges, OnDestroy {

  public allSub: Subscription[] = [];

  errorMessage: string = '';
  errorFirstName: boolean = false;
  errorLastName: boolean = false;
  errorEmail: boolean = false;
  isLoading: boolean = false;
  delay:number;

  public genderOptions: Array<RadioButtonItem> = [];
  @Input() localeTexts: any;
  @Input() isFromWeChat: boolean = false;
  @Input() isSignUp: boolean;
  @Input() form: FormGroup;
  @Output() changeViewComponent: EventEmitter<string> = new EventEmitter<string>();
  @Output() onSignUpError: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('submitButton') submitButton: ElementRef;
  @ViewChild('userDetailsSection') userDetailsSection: ElementRef;

  constructor(private uhfSsoService: UhfSsoService,private userDataService:UserdataService) { }

  ngOnInit() {
    this.userDetailsSection.nativeElement.focus();

    if (this.form && this.form.value.email && this.form.value.password) {
      this.isSignUp = true;
    }

    this.genderOptionsInit();
    
    if (this.isSignUp) {
      this.form.addControl('gender', new FormControl(DEFAULT_GENDER));
      this.form.addControl('firstName', new FormControl(null, [Validators.required]));
      this.form.addControl('lastName', new FormControl(null, [Validators.required]));
    } else {
      this.form = new FormGroup({
        'gender': new FormControl(DEFAULT_GENDER),
        'firstName': new FormControl(null, [Validators.required]),
        'lastName': new FormControl(null, [Validators.required])
      });

      if (this.isFromWeChat) {
        this.form.addControl('email', new FormControl(null, [Validators.required, Validators.email]));
      }
    }
  }

  ngOnChanges() {
    this.genderOptionsInit();
  }

  genderOptionsInit() {
    try {
      if (this.localeTexts.length == 0) return
      this.genderOptions = [];
      this.genderOptions.push(new RadioButtonItem(GENDER_OPTIONS.MALE, this.localeTexts['signupTab']['male']));
      this.genderOptions.push(new RadioButtonItem(GENDER_OPTIONS.FEMALE, this.localeTexts['signupTab']['female']));
    } catch (err) { }
  }

  onSubmit() {
    this.submitButton.nativeElement.focus();
    this.resetErrors();
    let firstNameValue = this.form.value.firstName;
    let lastNameValue = this.form.value.lastName;
    if (!firstNameValue) {
      this.setErrorMessage(ERRORS.EMPTY_FIRST_NAME);
      return;
    }
    if (!isName(firstNameValue)) {
      this.setErrorMessage(ERRORS.INVALID_FIRST_NAME);
      return;
    }
    if (!lastNameValue) {
      this.setErrorMessage(ERRORS.EMPTY_LAST_NAME);
      return;
    }
    if (!isName(lastNameValue)) {
      this.setErrorMessage(ERRORS.INVALID_LAST_NAME);
      return;
    }

    if (this.isFromWeChat) {
      let emailValue = this.form.value.email;
      if (!emailValue) {
        this.setErrorMessage(ERRORS.EMPTY_EMAIL);
        return;
      }
      if (!isEmail(emailValue)) {
        this.setErrorMessage(ERRORS.INVALID_EMAIL);
        return;
      }
    }

    this.isLoading = true;

    if (this.isSignUp) {
      let subSignUp = this.uhfSsoService.signUp(
        this.form.value.email,
        this.form.value.password,
        this.form.value.gender,
        firstNameValue,
        lastNameValue
      ).subscribe(
        userDetails => {
          // console.log("User signup susscessful", userDetails);
          // console.log("environment is",this.userDataService.getEnvironment())
          this.userDataService.getEnvironment()=='prod'?this.delay=5000:this.delay=25000;

          if (userDetails['id']) {
            this.uhfSsoService.setSignUpUserId(userDetails['id'])
          }
          
          // setTimeout(()=>{
            this.uhfSsoService.startInterval();
            this.uhfSsoService.notifyLogInStatus(true, userDetails);

            this.uhfSsoService.setTempEmail(this.form.value.email);
            this.changeViewComponent.emit(VIEW_COMPONENTS.ACTIVATION);

          // },this.delay)
        },
        error => {
          // console.log("Error in signup User", error);
          this.uhfSsoService.setTempEmail(this.form.value.email);
          if (error.error.code === ERRORS.USER_TERMINATED) {
            this.setErrorMessage(ERRORS.USER_ALREADY_EXISTS)
          } else {
            this.setErrorMessage(error.error.code);
          }
          this.uhfSsoService.notifyLogInStatus(false);
          this.uhfSsoService.clearInterval();
        }
      )
      subSignUp.add(() => this.isLoading = false);
      this.allSub.push(subSignUp);

    } else if (this.isFromWeChat) {
      // wechat signup scenario
      this.uhfSsoService.weChatSignupCall(
        this.uhfSsoService.getWeChatState(),
        this.uhfSsoService.getWeChatOpenId(),
        this.form.value.email,
        firstNameValue,
        lastNameValue,
        this.form.value.gender
      )
    } else {
      // update existing user (social media fb/gmail)
      let body = {
        gender: this.form.value.gender,
        firstName: firstNameValue,
        lastName: lastNameValue
      }
      let subUpdateUser = this.uhfSsoService.updateUserDetails(body)
      .subscribe(
        updateUserDetails => {
          // console.log("User Details Updated");
          this.changeViewComponent.emit(VIEW_COMPONENTS.CLOSE_MODAL);
          this.uhfSsoService.refreshUserSession();
        },
        error => {
          // console.log("Error in user Details supdating", error);
          this.setErrorMessage(error.error.code);
        }
       )
       subUpdateUser.add(() => this.isLoading = false);
      this.allSub.push(subUpdateUser);
    }
  }

  setErrorMessage(type: string) {
    var errors = {
      [ERRORS.EMPTY_FIRST_NAME]: () => {
        this.errorFirstName = true;
        return this.localeTexts['errors']['emptyGivenName'];
      },
      [ERRORS.INVALID_FIRST_NAME]: () => {
        this.errorFirstName = true;
        return this.localeTexts['errors']['romanAlphabetsOnly'];
      },
      [ERRORS.EMPTY_LAST_NAME]: () => {
        this.errorLastName = true;
        return this.localeTexts['errors']['emptyLastName'];
      },
      [ERRORS.INVALID_LAST_NAME]: () => {
        this.errorLastName = true;
        return this.localeTexts['errors']['romanAlphabetsOnly'];
      },
      [ERRORS.EMPTY_EMAIL]: () => {
        this.errorEmail = true;
        return this.localeTexts['errors']['emptyEmailAddress'];
      },
      [ERRORS.INVALID_EMAIL]: () => {
        this.errorEmail = true;
        return this.localeTexts['errors']['invalidEmail'];
      },
    }
    errors[type] ? this.errorMessage = errors[type]() : this.onSignUpError.emit(type);
  }

  resetErrors() {
    this.errorFirstName = false;
    this.errorLastName = false;
    this.errorEmail = false;
    this.errorMessage = '';
  }

  ngOnDestroy() {
    for (let sub of this.allSub) {
      sub.unsubscribe();
    }
  }
}
