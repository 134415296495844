export class Endpoints {

    public static sections: Object = {
        "language": "https://sch.apiairasia.com/universalheader/language/{locale}/file.json",
        "currency": "https://sch.apiairasia.com/universalheader/currency/{locale}/file.json",
        "navmenu": "https://sch.apiairasia.com/universalheader/navmenu/{locale}/file.json",
        "main": "https://sch.apiairasia.com/universalheader/main/{locale}/file.json",
        "loginpanel": "https://sch.apiairasia.com/universalheader/loginpanel/{locale}/file.json",
        "managebookingpanel": "https://sch.apiairasia.com/universalheader/managebookingpanel/{locale}/file.json",
        "loginmenu": "https://sch.apiairasia.com/universalheader/loginmenu/{locale}/file.json",
        "footer": "https://sch.apiairasia.com/universalheader/footer/{locale}/file.json",
        "tooltip": 'https://sch.apiairasia.com/universalheader/tooltip/{locale}/file.json',
        "sso": "https://sch.apiairasia.com/universalheader/ssowidget/{locale}/file.json"
    }


    public static stgSections: Object = {
        "language": "https://sch.apiairasia.com/universalheaderstg/language/{locale}/file.json",
        "currency": "https://sch.apiairasia.com/universalheaderstg/currency/{locale}/file.json",
        "navmenu": "https://sch.apiairasia.com/universalheaderstg/navmenu/{locale}/file.json",
        "main": "https://sch.apiairasia.com/universalheaderstg/main/{locale}/file.json",
        "loginpanel": "https://sch.apiairasia.com/universalheaderstg/loginpanel/{locale}/file.json",
        "managebookingpanel": "https://sch.apiairasia.com/universalheaderstg/managebookingpanel/{locale}/file.json",
        "loginmenu": "https://sch.apiairasia.com/universalheaderstg/loginmenu/{locale}/file.json",
        "footer": "https://sch.apiairasia.com/universalheaderstg/footer/{locale}/file.json",
        "tooltip": 'https://sch.apiairasia.com/universalheaderstg/tooltip/{locale}/file.json',
        "sso": "https://ppsch.apiairasia.com/universalheaderstg/ssowidget/{locale}/file.json"
    }

    public static getLocaleEndpoint(section: string, locale: string, env?: string) {
        if (env && env.toUpperCase() === 'PROD') {
            return Endpoints.sections[section].split('{locale}').join(locale);
        }
        return Endpoints.stgSections[section].split('{locale}').join(locale);
    }

    public static getBigPoints(env: string) {
        switch (env.toLowerCase()) {
            case 'prod':
                return `https://k.apiairasia.com/f_bp?env=${env.toLowerCase()}`

            default:
                return `https://k.stg-apiairasia.com/f_bp?env=${env.toLowerCase()}`
        }
    }

    public static getUserCountry() {
        return "https://t.airasia.com/nearestairport/airport/user";
    }

    public static ssoApisStg: Object = {
        "iteratorMilliSeconds": 60000,
        "origin": "daddf737-a290-4e55-8e75-1b1e729c1990",
        "ssoType": {
            "byOrigin": "https://stgsso-apigw.airasia.com/config/v2/clients/by-origin",//working
            "logIn": "https://stgsso-apigw.airasia.com/auth/v2/authorization/by-credentials",//cors error
            "refresh": "https://stgsso-apigw.airasia.com/auth/v2/authorization/by-refresh-token",//status 204
            "forgotPassword": "https://stgsso-apigw.airasia.com/um/v2/password-recovery-keys",//status 204
            "signUp": "https://stgsso-apigw.airasia.com/um/v2/users",//status 204
            "socialLogin": "https://stgsso-apigw.airasia.com/auth/v2/authorization/social/{network}?resultRedirectUrl={redirectUrl}&clientId={clientId}",
            "logOut": "https://stgsso-apigw.airasia.com/auth/v2/logout",//status 204
            "userDetails": "https://stgsso-apigw.airasia.com/um/v2/users/{userId}",
            "activateKeys": "https://stgsso-apigw.airasia.com/um/v2/user-activation-keys",
            "weChatLogin": "https://stgsso-apigw.airasia.com/auth/v2/authorization/social/wechat/callback",
            "weChatSignupVerify": "https://stgsso-apigw.airasia.com/sso/v2/authorization/social/wechat/callback",
            "backwardLogin": "https://stgsso-apigw.airasia.com/sso/v2/authorization/by-credentials",
            "backwardRefresh": "https://stgsso-apigw.airasia.com/sso/v2/authorization",
            "backwardLogout": "https://stgsso-apigw.airasia.com/sso/v2/logout",
        }
    }

    public static ssoApisProd: Object = {
        "iteratorMilliSeconds": 60000,
        "origin": "daddf737-a290-4e55-8e75-1b1e729c1990",
        "ssoType": {
            "byOrigin": "https://ssor.airasia.com/config/v2/clients/by-origin",//working
            "logIn": "https://ssor.airasia.com/auth/v2/authorization/by-credentials",//cors error
            "refresh": "https://ssor.airasia.com/auth/v2/authorization/by-refresh-token",//status 204
            "forgotPassword": "https://ssor.airasia.com/um/v2/password-recovery-keys",//status 204
            "signUp": "https://ssor.airasia.com/um/v2/users",//status 204
            "socialLogin": "https://ssor.airasia.com/auth/v2/authorization/social/{network}?resultRedirectUrl={redirectUrl}&clientId={clientId}",
            "logOut": "https://ssor.airasia.com/auth/v2/logout",//status 204
            "userDetails": "https://ssor.airasia.com/um/v2/users/{userId}",
            "activateKeys": "https://ssor.airasia.com/um/v2/user-activation-keys",
            "weChatLogin": "https://ssor.airasia.com/auth/v2/authorization/social/wechat/callback",
            "weChatSignupVerify": "https://ssor.airasia.com/sso/v2/authorization/social/wechat/callback",
            "backwardLogin": "https://ssor.airasia.com/sso/v2/authorization/by-credentials",
            "backwardRefresh": "https://ssor.airasia.com/sso/v2/authorization",
            "backwardLogout": "https://ssor.airasia.com/sso/v2/logout",
        }
    }
}
