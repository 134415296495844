import { Component, OnInit, Input, ViewEncapsulation, OnDestroy } from '@angular/core';
import { LocaleService } from '../../_services/locale.service';
import { UserdataService } from '../../_services/userdata.service';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { ComponentSettingService } from '../../_services/component-setting.service';
import { of, Subscription } from 'rxjs';


@Component({
  selector: 'aa-footer',
  template: `<footer id="wcaFooter" tabindex="0" *ngIf="isUHFRequired && isFooterEnabled && localeTexts.length" class="footer" [ngClass]="{'stick' : isSticky, 'hideOnMobile': !isMobile}" [attr.data-locale]="currentLocale">

  <div class="separator" *ngIf="isSeparatorRequired"></div>

  <div class="footerBox" [ngClass]=" {'w90' : (widthMode == 'less'), 'w80' : (widthMode == 'more')} ">
    <div class="footerLogo">
      <a rel="noreferrer noopener" href="{{localeTexts[0].footnote[0].content[0].url}}" target="{{localeTexts[0].footnote[0].content[0].newTab == 'TRUE' ? '_blank' : ''}}">
        <img src="{{localeTexts[0].footnote[0].content[0].imageURL}}" alt="{{localeTexts[0].footnote[0].content[0].altText}}" srcset="">
      </a>
    </div>

    <div class="footerTxt">
      <div class="f1">
        <p>
          <span [innerHTML]="firstFootNote"></span>
          <span>{{localeTexts[0].footnote[0].content[2].text}}</span>
          <span>{{localeTexts[0].footnote[0].content[3].text}}</span>
          <span class="lnk">
            <a rel="noreferrer noopener" href="{{localeTexts[0].footnote[0].content[4].url}}" target="{{localeTexts[0].footnote[0].content[4].newTab == 'TRUE' ? '_blank' : ''}}">
              {{localeTexts[0].footnote[0].content[4].text}}
            </a>
          </span>
        </p>
      </div>
      <div class="f2">

        <p>
          <span class="lnk" *ngFor="let link of localeTexts[0].menuList[0].content; let i = index">
            <a rel="noreferrer noopener" href="{{link.url}}" title="{{link.text}}" target="{{link.newTab == 'TRUE' ? '_blank' : ''}}">{{link.text}}</a>
            <span *ngIf="(i+1) != localeTexts[0].menuList[0].content.length">&nbsp;|&nbsp;</span>
          </span>
        </p>

      </div>
    </div>
  </div>

</footer>`,
  styles: [`.footer{width:100%;margin:0;font-size:.75rem;background-color:#f2f3f6;position:relative;float:left;padding-bottom:.625rem;outline:0}.footer a:-webkit-any-link{color:#e10000}.footer hr{margin-bottom:30px}.footer .lnk{color:#e10000!important;font-weight:400}.footer .f2{padding-top:.625rem}.footer .footerTxt{padding-top:.3125rem;color:#5a5d63;font-weight:400}.footer a:hover{text-decoration:underline}.footer .footerBox{position:relative;float:left}@media only screen and (max-width:1024px){.footer .footerBox{padding:20px 30px 75px 25px}}.footer .footerBox.w90{width:90%;margin-left:10%;left:-5%}.footer .footerBox.w80{width:80%;margin-left:20%;left:-10%}.footer.stick{position:fixed;bottom:.625rem}.footer .separator{border-bottom:1px solid #bfc5cf;margin-bottom:1.25rem}.footerLogo a{display:inline-block}@media screen and (min-width:1025px) and (max-width:1199px){.footer .footerBox{width:100%}.footer .footerTxt{position:absolute;top:0;padding-left:6.875rem;padding-top:0}}@media screen and (min-width:1200px){.footer .footerBox{width:75rem;margin-left:50%;left:-37.5rem}.footer .footerTxt{position:absolute;top:0;padding-left:6.875rem;padding-top:0}}@media screen and (max-width:1024px){.footer.hideOnMobile{display:none}}@media only screen and (min-width:1024px){.footer{background-color:#f2f3f6!important}}`],
  encapsulation: ViewEncapsulation.None
})
export class AaFooterComponent implements OnInit, OnDestroy {

  @Input() isSticky?: boolean;
  @Input() isMobile?: boolean;
  @Input() isSeparatorRequired: boolean;
  @Input() widthMode: string = 'none';
  public currentLocale: string;
  public localeTexts: Array<any> = [];
  public firstFootNote: string;
  public isFooterEnabled: boolean = true;
  public isUHFRequired: boolean = true;
  public allSub: Subscription[] = [];

  constructor(
    private localeService: LocaleService,
    private userDataService: UserdataService,
    private compSettingService: ComponentSettingService
  ) { }

  ngOnInit() {

    // Component setting lookup
    let compSetSub = this.compSettingService.UHFSettingChange$.subscribe(setting => {
      this.isUHFRequired = setting.isHeaderRequired;
      this.isFooterEnabled = setting.footerSection;
    });
    this.allSub.push(compSetSub);

    // Look for change in locale including on-load
    let locSub = this.userDataService.$localeChange
      .pipe(
        mergeMap(locale => {

          if (this.currentLocale != locale) {
            // console.log('___FOOTER___', this.currentLocale, '__', locale)
            this.currentLocale = locale;
            return this.localeService.loadRemoteLocale(['footer'], this.userDataService.getEnvironment())
          } else {
            return of([])
          }

        }),
        map(resp => {

          this.onLocaleLoad(resp);
        }),
        catchError(err => {

          // Fallback to en-gb
          let resp = this.localeService.getFbLocaleSections(['footer']);
          this.onLocaleLoad(resp);

          return of([])
        })
      )
      .subscribe()
    this.allSub.push(locSub);
  }

  onLocaleLoad(resp) {
    if (resp.length) {
      this.localeTexts.splice(0, this.localeTexts.length);
      resp.forEach(r => {
        this.localeTexts.push(r)
      });

      this.prepareFirstNote();
    }
  }

  prepareFirstNote() {
    // Prepare first foot note, since it has placeholders
    this.firstFootNote = this.localeTexts[0].footnote[0].content[1].text;
    let params = [];
    for(let i = 0; i < this.localeTexts[0].footnotelink[0].content.length; ++i)
      params.push(this.localeTexts[0].footnotelink[0].content[i])
  //  let params = [this.localeTexts[0].footnotelink[0].content[0], this.localeTexts[0].footnotelink[0].content[1], this.localeTexts[0].footnotelink[0].content[2]]
    if (params && params.length) {
      params.forEach((content, i) => {
        let newContent = `<a class="lnk" href="${content.url}" target="${content.newTab == 'TRUE' ? '_blank' : ''}">${content.text}</a>`
        this.firstFootNote = this.firstFootNote.split('{' + i + '}').join(newContent)
      })
    }
  }

  ngOnDestroy() {
    for (let sub of this.allSub) {
      sub.unsubscribe();
    }
  }

}
