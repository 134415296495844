import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'


@Component({
  selector: 'sso-sidebar',
  template: `<div id='sso-sidebar'>
  <h2 [innerHTML]='header'></h2>
  <div [innerHTML]='safeHtmlBody'></div>
</div>`,
  styles: [`button{border:none;background:0 0;cursor:pointer}.sso-social-items{display:flex;flex-direction:row;justify-content:space-around}.sso-social-items .sso-social-item{display:flex;flex-direction:column;align-items:center;cursor:pointer}.sso-social-items .sso-social-item .sso-social-icon{width:50px;height:50px;border-radius:50%}.sso-row{display:flex;flex-direction:row}.sso-column{display:flex;flex:1;flex-direction:column}.sso-button{width:100%;font-size:1.18rem;background:#e10000;border-radius:4px;border:none;padding:15px;color:#fff;cursor:pointer;display:flex;justify-content:center}.sso-button:disabled{cursor:progress}ul{list-style-type:none;padding:0}.sso-error-message{box-sizing:border-box;background-color:#ffebee;padding:12px;margin:15px 0;border-radius:5px;color:#e10000;width:100%;display:flex;font-size:.9rem;align-items:flex-start}.sso-error-message div{margin-left:10px;display:block;align-self:center}.sso-error-message a{text-decoration:underline}#sso-sidebar{padding:15px 25px}#sso-sidebar div{font-size:.9rem}h2{font-size:1.2rem;font-weight:700}`]
})

export class SsoSidebarComponent implements OnInit {

  @Input() header: string;
  @Input() body: string;

  safeHtmlBody: SafeHtml = '';

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.safeHtmlBody = this.sanitizer.bypassSecurityTrustHtml(this.body);
  }

  ngOnChanges() {
    this.safeHtmlBody = this.sanitizer.bypassSecurityTrustHtml(this.body);
  }
}
