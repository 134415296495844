export const ACCEPTED_PARAMS = {
  LOGIN: 'loginTab',
  SIGNUP: 'signupTab',
  FORGOT_PASSWORD: 'forgotPasswordTab'
}

export const VIEW_COMPONENTS = {
    LOGIN: 'loginTab',
    SIGNUP: 'signupTab',
    USER_DETAILS: 'userDetailsTab',
    WECHAT_USER_DETAILS: 'userDetailsWeChatTab',
    FORGOT_PASSWORD: 'forgotPasswordTab',
    ACTIVATION: 'activationTab',
    CLOSE_MODAL: 'closeModal'
}

export const GENDER_OPTIONS =  {
    MALE: 'male', 
    FEMALE: 'female'
}

export const ERRORS = {
  NEW_USER: 'USER_NEW',
  EMPTY_EMAIL: 'EMPTY_EMAIL',
  INVALID_EMAIL: 'INVALID_EMAIL',
  INVALID_USERNAME: 'INVALID_USERNAME',
  INVALID_PASSWORD: 'INVALID_PASWORD',
  INVALID_CREDENTIALS: 'INVALID_CREDENTIALS',
  USER_TERMINATED: 'USER_TERMINATED',
  USER_LOCKED: 'USER_LOCKED',
  USER_NOT_ACTIVATED: 'USER_NOT_ACTIVATED',
  USER_INACTIVE: 'USER_INACTIVE',
  USER_DEACTIVATED: 'USER_DEACTIVATED',
  USER_INCOMPLETE_DETAILS: 'USER_INCOMPLETE_DETAILS',
  SERVER_ERROR: 'SERVER_ERROR',
  EMPTY_FIRST_NAME: 'EMPTY_FIRST_NAME',
  INVALID_FIRST_NAME: 'INVALID_FIRST_NAME',
  EMPTY_LAST_NAME: 'EMPTY_LAST_NAME',
  INVALID_LAST_NAME: 'INVALID_LAST_NAME',
  USER_ALREADY_EXISTS: 'USER_ALREADY_EXISTS',
  INVALID_CREDENTIALS_TERMINATED: 'INVALID_CREDENTIALS_TERMINATED'
}

export function isEmail(value: string) {
  return /^[_A-Za-z0-9-]+(\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*(\.[A-Za-z]{2,10})$/i.test(value);
}

export function isPassword(value: string) {
  value = String(value);
  return /^(?=.*\d)(?=.*?[A-Z])(?=.*?[a-z])(?=.*[a-zA-Z.@#$%^&+=!])(?!.*[<>.,~]).{8,16}$/.test(value);
}

export function isName(value: string) {
  return /^[a-zA-Z ]+$/.test(value);
}
